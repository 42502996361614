import { FC } from "react";
import styles from './Stepper.module.scss'

const Stepper: FC<React.HTMLAttributes<HTMLDivElement>> = (props) => (
    <div className={`${props.className} ${styles.container}`}>
        {props.children}
    </div>
)

export default Stepper
