import { AxiosResponse } from 'axios'
import { FC, useEffect, useState } from 'react'
import { downloadFile } from '../../helper/helper'
import { useAxios } from '../../hooks/axioshook'
import Button from '../../shared_components/button/Button'
import Checkbox from '../../shared_components/checkbox/Checkbox'
import { CloseIcon, DownloadIcon } from '../../shared_components/icons/Icons'
import Link from '../../shared_components/link/Link'
import Loading from '../../shared_components/loading/Loading'
import Modal from '../../shared_components/modal/Modal'
import Text from '../../shared_components/text/Text'
import styles from './threeWayMatch.module.scss'

interface IthreeWayMatch {
    response?: AxiosResponse,
    buildId: string,
    open?: boolean,
    onClose: () => void,
}

const ThreeWayMatch: FC<IthreeWayMatch> = (props) => {

    const [po, setPo] = useState(false)
    const [invoice, setInvoice] = useState(false)
    const [grn, setGrn] = useState(false)

    const build = useAxios()

    const handleSubmit = () => {
        build.fetchData({
            method: 'POST',
            url: `/build/${props.buildId}/progress-update`,
            data: {
                updateType: '3-Way Match Completed',
            }
        })
    }
    
    // Refresh page
    useEffect(() => {
        if(build.response?.status === 201) {
            window.location.reload();
        }
    }, [build.loading])

    return (
        <>
        {
            props.open
            &&
            <Modal>
                <div className={styles.container}>
                    <Link 
                        className={styles.close}
                        onClick={props.onClose}
                    >
                        <CloseIcon color={'dark'} />
                    </Link>
                    <Text size={'x-lg'} type={'bold'}>3-Way Match</Text>
                    <Text>
                        Select all of the checkboxes to confirm that the
                        following three items match for this order.
                    </Text>
                    <br/>

                    <div className={styles.checkboxes}>
                        <div>
                            <Checkbox
                                onChange={() => setPo(prev => !prev)}
                            >
                                Purchase Order
                            </Checkbox>
                            <Button type={'minimal'}>
                                {/* <DownloadIcon color={'medium'}
                                    onClick={() => downloadFile(id?, props.response)}
                                /> */}
                            </Button>
                        </div>
                        <div>
                            <Checkbox
                                onChange={() => setInvoice(prev => !prev)}
                            >
                                Invoice
                            </Checkbox>
                            <Button type={'minimal'}>
                                {/* <DownloadIcon color={'medium'}
                                    onClick={() => downloadFile()}
                                /> */}
                            </Button>
                        </div>
                        <div>
                            <Checkbox
                                onChange={() => setGrn(prev => !prev)}
                            >
                                GRN
                            </Checkbox>
                            <Button type={'minimal'}>
                                {/* <DownloadIcon color={'medium'}
                                    onClick={() => downloadFile()}
                                /> */}
                            </Button>
                        </div>
                    </div>
                    <br/>

                    <div className={styles.buttons}>
                        <Button type={'secondary'}
                            onClick={() => props.onClose()}
                        >
                            Reject
                        </Button>
                        <Button
                            disabled={!po || !invoice || !grn}
                            onClick={handleSubmit}
                        >
                            Accept
                        </Button>
                    </div>
                    {
                        build.loading
                        &&
                        <Loading />
                    }
                </div>
            </Modal>
        }
        </>
    )
}

export default ThreeWayMatch
