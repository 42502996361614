import { FC } from 'react'
import Text from '../text/Text'
import styles from './Radio.module.scss'

const Radio: FC<React.InputHTMLAttributes<HTMLInputElement>> = (props) => (
    <label className={styles.container}>
        <input 
            type={'radio'} 
            name={props.name} 
            checked={props.checked}
            onChange={props.onChange}
            disabled={props.disabled}
        />
        <Text>{props.children}</Text>
    </label>
)

export default Radio
