import { FC } from "react";
import styles from './Modal.module.scss'

const Modal: FC<React.HTMLAttributes<HTMLDivElement>> = (props) => (
    <div className={styles.container}>
        {props.children}
    </div>
)

export default Modal
