import { FC, ReactNode, useState } from 'react'
import { ArrowDownIcon } from '../icons/Icons'
import styles from './Tooltip.module.scss'

interface ITooltip extends React.HTMLAttributes<HTMLDivElement> {
    content: ReactNode,
    arrow? : 'left' | 'right' | 'top',
}

const Tooltip: FC<ITooltip> = (props) => {

    const [show, setShow] = useState(false)
    const arrow = props.arrow ?? 'left'

    return (
        <>
            {
                show
                &&
                <div className={`${styles.container} ${props.className}`}>
                    <ArrowDownIcon
                        className={arrow === 'left' ? styles.arrow_left : arrow === 'right' ? styles.arrow_right : styles.arrow_top}
                        variant={'bold'}
                        color={'medium'}
                    />
                    {props.content}
                </div>
            }
            <div
                onMouseOver={() => setShow(true)}
                onMouseOut={() => setShow(false)}
            >
                {props.children}
            </div>
        </>
    )   
}

export default Tooltip
