import { FC } from 'react'
import styles from './Link.module.scss'

const Link: FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> = (props) => (
    <span className={`${props.className} ${styles.container}`}>
        <a
            href={props.href}
            onClick={props.onClick}
        >
                {props.children}
        </a>
    </span>
)   

export default Link
