import { FC, useEffect, useState } from 'react'
import PlannedHeader from '../../components/plannedHeader/PlannedHeader'
import { useAxios } from '../../hooks/axioshook'
import Loading from '../../shared_components/loading/Loading'
import Table from '../../shared_components/table/Table'
import { Design, DesignNew } from '../../types/types'
import styles from './PlannedPOPage.module.scss'
import { useNavigate } from 'react-router-dom'

const PlannedPOPage: FC = () => {

    const recipes = useAxios()
    const [content, setContent] = useState([])
    const [filter, setFilter] = useState('')
    const navigate = useNavigate()
    const recipeCount = useAxios()
    const [count, setCount] = useState(0)
    useEffect(() => {
        recipes.fetchData({
            method: 'GET',
            url: '/recipe?limit=10&page=1'
        })
    }, [recipes.hasToken])

    useEffect(() => {
        recipeCount.fetchData({
            method: 'GET',
            url: '/recipe/count'
        })
    }, [recipeCount.hasToken])

    useEffect(() => {
        if(recipeCount.response?.data && !recipeCount.loading){
            setCount(recipeCount.response.data.count)
        }
    },[recipeCount.loading])


    useEffect(() => {
        if (recipes.response?.data && !recipes.loading) {
            setContent(recipes.response?.data.map((order: Design, key: number) => {
                return [
                    { value: order.externalId, id: order.id },
                    { value: order.name },
                    { value: order.supplier },
                    { value: order.price },
                    { value: order.material },
                    { value: order.alloy },
                ]
            }))
        }
    }, [recipes.loading])
    const handleRowClick = (item: object) => {
        const values = JSON.parse(JSON.stringify(item))
        let designNew:DesignNew = {
            id: values[0].id,
            externalId: values[0].value,
            name: values[1].value,
            supplier: values[2].value,
            price: values[3].value
        }
        navigate('./issue-purchase-order', {
            state: {
                design: designNew
            }
        })
    } 

    const handlePaginationClick = async (page: number,rowsPerPage: number) => {
        await recipes.fetchData({
            method: 'GET',
            url: `/recipe?limit=${rowsPerPage}&page=${page}`
        })
    }

    const handleRowsPerPageClick =  async (rowsPerPage: number, page: number) => {
        await recipes.fetchData({
            method: 'GET',
            url: `/recipe?limit=${rowsPerPage}&page=${page}`
        })
    }

    return (
        <div className={styles.container}>
            <PlannedHeader
                onChange={value => setFilter(value)}
            />
            <div className={styles.content}>
                <Table
                    header={['Design ID', 'Name', 'Supplier', 'Price', 'Material', 'Alloy']}
                    data={content}
                    filter={{ query: filter, indexes: [0, 1] }}
                    onRowClick={(item) => handleRowClick(item)}
                    onPaginationClick={(page,rowsPerPage) => handlePaginationClick(page,rowsPerPage)}
                    totalCount={count}
                    onRowsPerPageClick={(rowsPerPage,page) => handleRowsPerPageClick(rowsPerPage,page)}
                />
            </div>
            {
                recipes.loading
                &&
                <Loading />
            }
        </div>
    )
}

export default PlannedPOPage
