import { Link, useLocation } from "react-router-dom"

const formatTitle = (text: string): string => {
    const path: Array<string> = text.split('-')
    var formatted: string = ''
    for(var i=0; i< path.length; i++){
        const slice = path[i].charAt(0).toUpperCase() + path[i].slice(1)
        formatted += ` ${slice}`
    }
    return formatted.trim()
}

const Breadcrumbs = () => {

    const location: Array<string> = useLocation().pathname.split('/')
    const current = formatTitle(location[location.length-1])
    location.pop()
    const breadcrumbs = []

    while(location.length > 1) {
        let path = location.join('/')
        let text = location.pop() || ''
        
        breadcrumbs.push({path, text: formatTitle(text)})
    }
        
    return (
        <>
            &emsp;&#62;
            {breadcrumbs.reverse().map((element, index) =>
                <span key={index}>
                    <Link to={element.path}>{element.text}</Link>&emsp;&#62;
                </span> 
                
            )}

            <span>{current}</span>
        </>
    )
}

export default Breadcrumbs
