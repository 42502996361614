import styles from './AccountMenu.module.scss'
import { FC } from "react"
import { ProfileIcon, LogoutIcon } from '../../shared_components/icons/Icons'
import Link from '../../shared_components/link/Link'
import Menu from '../../shared_components/menu/Menu'
import MenuItem from '../../shared_components/menu/MenuItem'
import Text from '../../shared_components/text/Text'
import { useAuth0 } from '@auth0/auth0-react'

interface IAccountMenu {
    username: string,
}

const AccountMenu: FC<IAccountMenu> = (props) => {

    const {user, logout} = useAuth0()

    return (
        <div className={styles.container}>
            <Menu
                className={styles.menu}
                label={<Text>{user?.name}</Text>}
            >
                <MenuItem>
                    <Link onClick={()=>{}}>
                        <ProfileIcon/>
                        <Text>Your Account</Text>
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link onClick={() => logout({ returnTo: window.location.origin })}>
                        <LogoutIcon/>
                        <Text>Logout</Text>
                    </Link>
                </MenuItem>
            </Menu>
        </div>
    )             
}

export default AccountMenu
