import Text from '../../shared_components/text/Text'
import styles from './AppButton.module.scss'

export interface AppButtonProps {
    image: string,
    label: string,
    onClick: React.MouseEventHandler<HTMLDivElement>,
}

const AppButton = (props: AppButtonProps) => (
    <div className={styles.container} onClick={props.onClick}>
        <div className={styles.image}>
            <img alt='logo' src={props.image} />
        </div>
        <br/>
        <div className={styles.label}>
            <Text size={'lg'} type={'semibold'}>{props.label}</Text>
        </div>
    </div>
)


export default AppButton