import { FC, useEffect, useState } from 'react'
import { useAxios } from '../../hooks/axioshook'
import Button from '../../shared_components/button/Button'
import FileUploader from '../../shared_components/fileUploader/FileUploader'
import { CloseIcon } from '../../shared_components/icons/Icons'
import Link from '../../shared_components/link/Link'
import Loading from '../../shared_components/loading/Loading'
import Modal from '../../shared_components/modal/Modal'
import Radio from '../../shared_components/radio/Radio'
import Text from '../../shared_components/text/Text'
import { AttachmentResponse, Design, OrderResponse } from '../../types/types'
import styles from './AddFile.module.scss'

interface IAddFile {
    partId?: string,
    recipeId?: string,
    final?: boolean,
    open?: boolean,
    onClose: () => void,
    radioHidden: boolean
    text:string
    uploadType: string
    buildId?: string,
    forecastedDeliveryDate?: string
    updateType?: string
    radioOptions?: string[]
}

const AddFile: FC<IAddFile> = (props) => {

    const [type, setType] = useState({description: '', index: 0})
    const [content, setContent] = useState<Design>()
    const [file, setFile] = useState<File | null>(null)
    const [error, setError] = useState('')

    const recipe = useAxios()
    const fileRequest = useAxios()
    const certification = useAxios()
    const progressUpdate = useAxios()
    const buildComplete = useAxios()
    useEffect(() => {
        if(props.recipeId){
            recipe.fetchData({
                method: 'GET',
                url: `/recipe/${props.recipeId}`
            })
        }
    }, [recipe.hasToken])

    useEffect(() => {
        if(recipe.response?.status === 200) {
            setContent(recipe.response.data)
        }
    }, [recipe.loading])

    const onChange = (success: File | null, err: string) => {
        setFile(success)
        setError(err)   
    }

    const handleSubmit = () => {
        // First create the attachment with file
        const formData = new FormData()
        formData.append('file',file as File)
        fileRequest.fetchData({
            method: 'POST',
            url: '/attachment',
            data: formData,
        })
    }
    useEffect(() => {
        if(fileRequest.response?.status === 201 && props.uploadType === 'certification') {
            const data = fileRequest.response.data as AttachmentResponse
            // Convert Cert Description to Status Update
            var updateType = type.description + ' Uploaded'
            if (type.description === "HIP CofC") updateType = "HIP Completed"
            if (type.description === "Final CofC Pack") updateType = "Final Inspection Completed"
            // Second call part/:id/certification with attachment
            certification.fetchData({
                method: 'POST',
                url: `/part/${props.partId}/certification`,
                data: {
                    attachmentId: data.id,
                    certificationIndex: type.index,
                    certificationType: updateType,
                }
            })
        }
        else if(fileRequest.response?.status === 201 && props.uploadType === 'buildProgressUpdate'){
            const data = fileRequest.response.data as AttachmentResponse
            progressUpdate.fetchData({
                method: 'POST',
                url: `/build/${props.buildId}/progress-update`,
                data: {
                    attachmentId: data.id,
                    updateType: props.updateType,
                    completionEstimate: props.forecastedDeliveryDate
                },
            })
        }
        else if(fileRequest.response?.status === 201 && props.uploadType === 'buildComplete'){
            const data = fileRequest.response.data as AttachmentResponse
            const date = new Date()
            buildComplete.fetchData({
                method: 'POST',
                url: `/build/${props.buildId}/completion`,
                data: {
                    attachmentId: data.id,
                    completedAt: date.toISOString()
                },
            })
        }
    }, [fileRequest.loading])

    // Refresh page
    useEffect(() => {
        if(certification.response?.status === 201) {
            window.location.reload();
        }
    }, [certification.loading])

    useEffect(() => {
        if(progressUpdate.response?.status === 201) {
            window.location.reload();
        }
    }, [progressUpdate.loading])

    useEffect(() => {
        if(buildComplete.response?.status === 201) {
            window.location.reload();
        }
    }, [buildComplete.loading])

    return (
        <>
        {
            props.open
            &&
            <Modal>
                <div className={styles.container}>
                    <Link 
                        className={styles.close}
                        onClick={props.onClose}
                    >
                        <CloseIcon color={'dark'} />
                    </Link>
                    <Text size={'x-lg'} type={'bold'}>Add New File</Text>
                    {
                        !props.radioHidden
                        &&
                        <div className={styles.type}>
                        <Text>1.  Select the type of file you are adding to this order.</Text>
                        {
                            (
                            content?.requiredCerts.map((item, key) => {
                                return (<Radio
                                    key={key}
                                    name={'type'}
                                    disabled={ props.final && !(item.description.toLowerCase().indexOf("final") > -1) }
                                    onChange={() => setType({description: item.description, index: key})}
                                >
                                    {item.description}
                                </Radio>)
                                })
                            )
                            ||
                            (
                                props.radioOptions?.map((item, key) => {
                                    return (<Radio
                                    key={key}
                                    name={'type'}
                                    onChange={() => setType({description: item, index: key})}
                                >
                                    {item}
                                </Radio>)
                                })
                            )
                        }
                        </div>
                    }
                    <br/>
                    <div className={styles.file}>
                        <Text>{props.text}</Text>
                        <div className={styles.uploader}>
                            <FileUploader onChange={(file, error)=>onChange(file, error)}/>
                        </div>
                    </div>
                    <br/>
                    <Button
                        disabled={file === null || error !== '' || (!props.radioHidden && type.description === '')}
                        onClick={handleSubmit}
                    >
                        Upload
                    </Button>
                    {
                        (fileRequest.loading || certification.loading || progressUpdate.loading || buildComplete.loading)
                        &&
                        <Loading />
                    }
                </div>
            </Modal>
        }
        </>
    )
}

export default AddFile
