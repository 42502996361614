import styles from './Text.module.scss'
import { FC } from "react"

export interface IText extends React.HTMLAttributes<HTMLElement> {
    type?: 'regular' | 'medium' | 'semibold' | 'bold',
    size?: 'x-sm'| 'sm' | 'md' | 'lg' | 'x-lg',
    color?: 'default' | 'defaultBlue' | 'medium' | 'error' | 'warning' | 'success' | 'inherit',
}

const Text: FC<IText> = (props) => {
    
    //defaults
    const type = props.type ?? 'regular'
    const size = props.size ?? 'md'
    const color = props.color ?? 'default'

    return (
        <span className={`${props.className} ${styles.text} ${styles[type]} ${styles[size]} ${styles[color]}`}>
            {props.children}
        </span>
    )
}

export default Text
