import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { BoxSearchIcon, HomeIcon, LocationIcon, NotificationIcon } from '../../shared_components/icons/Icons'
import Text from '../../shared_components/text/Text'
import styles from './SideBar.module.scss'

const SideBar = () => {

    const persona = process.env.REACT_APP_PERSONA?.toUpperCase()
    let orderHeader : string
    let ordersText : string

    const [notificationsCount, setNotificationsCount] = useState(0)
    const location = useLocation().pathname

    useEffect(() => {
        setNotificationsCount(7) //TODO: replace by api request
    }, [])

    if(persona === 'BUYER') {
            orderHeader = 'Purchase Order'
            ordersText = 'Active'
    } else if (persona === 'SUPPLIER') {
            orderHeader = 'Sales Order'
            ordersText = 'Order History'
    } else {
            orderHeader = 'Sales Order'
            ordersText = 'Order History'
    }

    return (
        <div className={styles.container}>
            <div className={`${styles.item} ${location === '/overview' && styles.selected}`}>
                <HomeIcon color={'inherit'} />
                <div className={styles.bar}></div>
                <Link to='/overview'>
                    <Text color={'inherit'} type={location === '/overview' ? 'medium' : undefined } >Overview</Text>
                </Link>
            </div>

            <div className={`${styles.item} ${/orders+/.test(location) && styles.selected}`}>
                <BoxSearchIcon color={'inherit'} />
                <div className={styles.bar}></div>
                <Link to='/orders'>
                    <Text color={'inherit'} type={/orders+/.test(location) ? 'medium' : undefined } >{orderHeader}</Text>
                </Link>
            </div>
            <div className={`${styles.subitem} ${location === '/orders' && styles.selected}`}>
                <Link to='/orders'>
                    <Text color={'inherit'} type={location === '/orders' ? 'medium' : undefined } >{ordersText}</Text>
                </Link>
            </div>    
                {
                    (() => {
                        if(persona === 'BUYER') {
                            return (
                            <div className={`${styles.subitem} ${location === '/orders/planned' && styles.selected}`}>
                                <Link to='/orders/planned'>
                                    <Text color={'inherit'} type={location === '/orders/planned' ? 'medium' : undefined } >Planned</Text>
                                </Link>
                            </div>
                            )
                        } else {
                            return (
                                null
                            )
                        }
                    })()  
                }  

            <div className={`${styles.item} ${location === '/track' && styles.selected}`}>
                <LocationIcon color={'inherit'} />
                <div className={styles.bar}></div>
                <Link to='/track'>
                    <Text color={'inherit'} type={location === '/track' ? 'medium' : undefined } >Track</Text>
                </Link>
            </div>

            <div className={`${styles.item} ${location === '/notifications' && styles.selected}`}>
                <NotificationIcon color={'inherit'} />
                <div className={styles.bar}></div>
                <Link to='/notifications'>
                    <Text color={'inherit'} type={location === '/notifications' ? 'medium' : undefined } >Notifications</Text>
                </Link>
                <div className={styles.badge}>
                    <Text color={'inherit'} size={'sm'}>{notificationsCount}</Text>
                </div>
            </div>
        </div>
    )
}

export default SideBar
