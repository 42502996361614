import styles from './TrackPage.module.scss'
import { FC, useState, useEffect, Fragment } from 'react'
import lime_logo from '../../assets/logos/lime.svg'
import MapWithIcons from '../../components/tracking/MapWithIcons';
import Input from '../../shared_components/input/Input';
import { useAxios } from '../../hooks/axioshook';
import { PartResponse } from '../../types/types';
import Loading from '../../shared_components/loading/Loading';
import PartTracking from '../../components/partTracking/PartTracking';

const TrackPage: FC = () => {

    const persona = process.env.REACT_APP_PERSONA?.toUpperCase()
    let searchBarText: string
    const parts = useAxios()
    const [content, setContent] = useState<PartResponse[]>()
    const [filter, setFilter] = useState<PartResponse[]>()

    if (persona === 'BUYER') {
        searchBarText = 'Part'
    } else if (persona === 'SUPPLIER') {
        searchBarText = 'Job'
    } else {
        searchBarText = 'Job'
    }
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(content?.filter((item) => {
            return item.id.includes(e.target.value)
        }))
    }
    useEffect(() => {
        parts.fetchData({
            method: 'GET',
            url: '/part?metadataType=location'
        })
    }, [parts.hasToken])

    useEffect(() => {
        if (parts.response?.status === 200) {
            setContent(parts.response.data)
            setFilter(parts.response.data)
        }
    }, [parts.loading])


    return (
        <>
            <div className={styles.container} >
            <div className={styles.content}>
                <div className={styles.header} >
                    <img src={lime_logo} />
                    <Input
                        type={'search'}
                        placeholder={'Search for ' + searchBarText + ' Number'}
                        onChange={handleSearch}
                    />
                </div>
                
                    {
                        filter && filter.map((item, key) => {
                            return (
                                <Fragment key={key}>
                                    <PartTracking
                                        part={item}
                                    />
                                </Fragment>
                            )
                        })
                    }
                </div>
                <MapWithIcons />
                {
                    (parts.loading)
                    &&
                    <Loading />
                }

            </div>
            
        </>
    )
}

export default TrackPage
