import { Route, Routes } from 'react-router-dom'
import Navigation from './components/navigation/Navigation'
import OverviewPage from './pages/overview/OverviewPage'
import HomePage from './pages/home/HomePage'
import NotificationsPage from './pages/notifications/NotificationsPage'
import SaveDesignPage from './pages/saveDesign/SaveDesignPage'
import IssuePOPage from './pages/issuePO/IssuePOPage'
import PlannedPOPage from './pages/plannedPO/PlannedPOPage'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from './shared_components/loading/Loading';
import ActivePOPage from './pages/activePO/ActivePOPage'
import DetailsPOPage from './pages/detailsPO/DetailsPOPage'
import TrackPage from './pages/tracking/TrackPage'

const AppRoutes: React.FC = () => {
    
    const Private: React.FC<{children: React.ReactElement}> = ({children}) => {
        const auth = useAuth0()

        if(auth.isLoading) {
            return <Loading/>
        }

        if(!auth.isAuthenticated) {
            auth.loginWithRedirect()
            return <Loading/>
        }

        return children
    }

    return (
            <Routes>
                {/* <Route path='/login' element={<LoginPage />} /> */}
                <Route path='/' element={<Private><Navigation/></Private>}>
                    <Route index element={<Private><HomePage /></Private>}/>
                    <Route path='overview' element={<Private><OverviewPage /></Private>} />
                    <Route path='orders' element={<Private><ActivePOPage /></Private>} />
                    <Route path='orders/:id' element={<Private><DetailsPOPage /></Private>} />
                    <Route path='orders/:id/amend' element={<Private><IssuePOPage /></Private>} />
                    <Route path='orders/:id/view-order' element={<Private><IssuePOPage /></Private>} />
                    <Route path='orders/planned' element={<Private><PlannedPOPage /></Private>} />
                    <Route path='orders/planned/add-new-design' element={<Private><SaveDesignPage /></Private>} />
                    <Route path='orders/planned/issue-purchase-order' element={<Private><IssuePOPage /></Private>} />
                    <Route path='track' element={<Private><TrackPage /></Private>} />
                    <Route path='notifications' element={<Private><NotificationsPage /></Private>} />
                </Route>
            </Routes>
    )
}

export default AppRoutes
