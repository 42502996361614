import AppRoutes from './AppRoutes'
import { BrowserRouter } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'

function App() {
  return (
    <BrowserRouter>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ""}
        clientId={process.env.REACT_APP_AUTH0_CLIENTID ?? ""}
        redirectUri={window.location.origin}
        audience={process.env.REACT_APP_AUTH0_API_AUDIENCE ?? ""}
        scope={process.env.REACT_APP_AUTH0_SCOPE ?? ""}
      >
        <AppRoutes />
      </Auth0Provider>
    </BrowserRouter>

  )
}

export default App
