import styles from './Button.module.scss'
import { FC, useState } from "react"
import Text from '../text/Text'
import { DocumentIcon, DownloadIcon } from '../icons/Icons'

export interface IButton extends React.HTMLAttributes<HTMLButtonElement> {
    disabled?: boolean,
    type?: 'primary' | 'secondary' | 'minimal' | 'download' | 'notification' | 'cancelled',
}

const Button: FC<IButton> = (props) => {
    
    //default
    const type = props.type ?? 'primary'
    const [icon, setIcon] = useState(<DocumentIcon color={'medium'} variant={'bold'}/>)

    const handleHover = () => {
        if(!props.disabled) {
            setIcon(<DownloadIcon color={'medium'} variant={'bold'}/>)
        }
    }

    const handleLeave = () => {
        setIcon(<DocumentIcon color={'medium'} variant={'bold'}/>)
    }

    return (
        <button 
            className={`${props.className} ${styles.common} ${styles[type]}`}
            onClick={props.onClick} 
            disabled={props.disabled}
            onMouseOver={handleHover}
            onMouseLeave={handleLeave}
        >
            <Text color={'inherit'}>
                {
                    props.type === 'download'
                    &&
                    icon
                }
                {props.children}
            </Text>
        </button>
    )
}

export default Button
