import axios, { AxiosResponse } from "axios"
import { History, OrderResponse, StepResponse } from "../types/types"

export const formatDate = (iso: string): string => {
    const date = new Date(iso)
    const day = ('0' + date.getDate()).substring(('0' + date.getDate()).length - 2)
    const month = ('0' + (date.getMonth() + 1)).substring(('0' + (date.getMonth() + 1)).length - 2)
    const year = date.getFullYear().toString().substring(date.getFullYear().toString().length - 2)

    return `${day}/${month}/${year}`
}

export const getFileName = (response: AxiosResponse, id?: string) => {

    if (response.headers['content-type'].substring(0, 16) === 'application/json') {
        return `${id}.json`
    }

    const start = response.headers['content-disposition'].indexOf('"')
    const length = response.headers['content-disposition'].length

    return response.headers['content-disposition'].substring(start + 1, length - 1)
}

export const downloadFile = (response: AxiosResponse | undefined, id: string) => {
    axios({
        url: `${process.env.REACT_APP_BASE_URL}attachment/${id}`,
        method: 'GET',
        responseType: 'blob',
        headers: { Authorization: response?.config.headers?.Authorization ?? '' }
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', getFileName(response, id))
        document.body.appendChild(link)
        link.click()
    })
}

export const getProgress = (order: OrderResponse): { progress: number, stage: string } => {

    if (order.parts[0].buildStatus === 'Part Received') return { progress: 100, stage: 'Ship & Receive' }
    if (order.parts[0].buildStatus === 'Completed') return { progress: 90, stage: 'Ship & Receive' }
    if (order.parts[0].updateType === 'Final Inspection Completed') return { progress: 80, stage: 'Manufacture & Monitor' }
    if (order.parts[0].updateType === 'Machining and NDT Completed') return { progress: 70, stage: 'Manufacture & Monitor' }
    if (order.parts[0].updateType === 'HIP Completed') return { progress: 60, stage: 'Manufacture & Monitor' }
    if (order.parts[0].updateType === 'Lime Tracking ID Attached') return { progress: 50, stage: 'Manufacture & Monitor' }
    if (order.parts[0].updateType === 'Encapsulation Completed') return { progress: 40, stage: 'Manufacture & Monitor' }
    if (order.parts[0].buildStatus === 'Started') return { progress: 30, stage: 'Manufacture & Monitor' }
    if (order.status === 'Accepted') return { progress: 20, stage: 'Order' }

    return { progress: 10, stage: 'Order' }
}

const persona = process.env.REACT_APP_PERSONA?.toUpperCase()

export const getStage = (order: OrderResponse): { stage: string, pendingAction?: string, tooltipUpdate?: string, popupType?: string, updatedOn?: string, actions?: string[] } => {
    // Set stage depending on part or build status
    if (order.parts[0].updateType === '3-Way Match Completed')
        return { stage: '3-Way Match Completed', pendingAction: 'n/a', updatedOn: formatDate(order.parts[0].updatedAt) }
    if (order.parts[0].buildStatus === 'Part Received') {
        if (persona === 'SUPPLIER') return { stage: 'Part Received', pendingAction: 'n/a', updatedOn: formatDate(order.parts[0].updatedAt) }
        if (persona !== 'SUPPLIER') return { stage: 'Part Received', pendingAction: 'Complete 3-way match', popupType: 'Part Received', tooltipUpdate: 'Part Received', updatedOn: formatDate(order.parts[0].updatedAt) }
    }
    if (order.parts[0].buildStatus === 'Completed') {
        if (persona !== 'SUPPLIER') return { stage: 'Invoice Received', pendingAction: 'Upload a Goods Receipt Note', popupType: 'Upload GRN', tooltipUpdate: 'Invoice Received', updatedOn: formatDate(order.parts[0].updatedAt) }
        if (persona === 'SUPPLIER') return { stage: 'Invoice Received', pendingAction: 'n/a', updatedOn: formatDate(order.parts[0].updatedAt) }
    }
    if (order.parts[0].updateType === 'ASN Uploaded')
        return { stage: 'ASN Uploaded', pendingAction: 'Upload Invoice', popupType: 'Upload Invoice', updatedOn: formatDate(order.parts[0].updatedAt) }
    if (order.parts[0].updateType === 'Invoice Uploaded')
        return { stage: 'Invoice Uploaded', pendingAction: 'Upload ASN', popupType: 'Upload ASN', updatedOn: formatDate(order.parts[0].updatedAt) }
    if (order.parts[0].updateType === 'Final Inspection Completed')
        return { stage: 'Final Inspection Completed', pendingAction: 'Upload ASN & Invoice', popupType: 'Upload ASN & Invoice', actions: ['Upload ASN', 'Upload Invoice'], updatedOn: formatDate(order.parts[0].updatedAt) }
    if (order.parts[0].updateType === 'Machining and NDT Completed')
        return { stage: 'Machining and NDT Completed', pendingAction: 'Upload CofC', popupType: 'Upload Final CofC', updatedOn: formatDate(order.parts[0].updatedAt) }
    if (order.parts[0].updateType === 'HIP Completed')
        return { stage: 'HIP Completed', pendingAction: 'Update Process', popupType: 'HIP Completed', updatedOn: formatDate(order.parts[0].updatedAt) }
    if (order.parts[0].updateType === 'Lime Tracking ID Attached')
        return { stage: 'Lime Tracking ID Attached', pendingAction: 'Upload CofC', popupType: 'Upload CofC', updatedOn: formatDate(order.parts[0].updatedAt) }
    if (order.parts[0].updateType === 'Encapsulation Completed')
        return { stage: 'Encapsulation Completed', pendingAction: 'Set Up Item Tracking', popupType: 'Set Up Item Tracking', updatedOn: formatDate(order.parts[0].updatedAt) }
    if (order.parts[0].buildStatus === 'Started')
        return { stage: 'Build Started', pendingAction: 'Update Process', popupType: 'Build Started', updatedOn: formatDate(order.parts[0].updatedAt) }
    if (order.status === 'Accepted')
        return { stage: 'Purchase Order Accepted', pendingAction: 'Assign Job', popupType: 'Assign Job', updatedOn: formatDate(order.updatedAt) }
    if (order.status === 'Submitted') {
        if (persona !== 'SUPPLIER') return { stage: 'Purchase Order Shared', updatedOn: formatDate(order.updatedAt) }
        if (persona === 'SUPPLIER') return { stage: 'Purchase Order Received', pendingAction: 'Review Purchase Order', updatedOn: formatDate(order.updatedAt) }
    }
    if (order.status === 'AcknowledgedWithExceptions') {
        if (persona !== 'SUPPLIER') return { stage: 'Purchase Order Shared', tooltipUpdate: 'Exception', updatedOn: formatDate(order.updatedAt) }
        if (persona === 'SUPPLIER') return { stage: 'Purchase Order Acknowledged', pendingAction: 'n/a', updatedOn: formatDate(order.updatedAt) }
    }
    if (order.status === 'Amended') {
        if (persona !== 'SUPPLIER') return { stage: 'Purchase Order Amended', updatedOn: formatDate(order.updatedAt) }
        if (persona === 'SUPPLIER') return { stage: 'Purchase Order Amended', pendingAction: 'Review Purchase Order', updatedOn: formatDate(order.updatedAt) }
    }
    if (order.status === 'Cancelled') 
        return { stage: 'Purchase Order Cancelled', pendingAction: 'n/a', updatedOn: formatDate(order.updatedAt) }
    return { stage: '' }
}

export const getStepper = (history: History[], persona: string, confirmedReceiptDate: string): { steps: StepResponse[] } => {
    const steps = new Array()
    // initialise the steps, there are 11 steps by default now, one is empty
    steps[0] = { label: 'Purchase Order Received', type: 'current' }
    steps[1] = { label: 'Purchase Order Accepted', type: 'next' }
    steps[2] = { label: 'Manufacturing Job Started', type: 'next' }
    steps[3] = { label: 'Capsule Filling Completed', type: 'next' }
    steps[4] = { label: 'Lime Tracking ID Attached', type: 'next' }
    steps[5] = { label: 'HIP Completed', type: 'next' }
    steps[6] = { label: 'Machining and NDT Completed', type: 'next' }
    steps[7] = { label: 'Final Inspection Completed', type: 'next' }
    steps[8] = {}   // Empty step to hide ASN Uploaded step
    steps[9] = { label: 'Shipped & Invoice Shared', type: 'next' }
    steps[10] = { label: 'Part Received', type: 'next', lastStep: true }

    if (persona === 'BUYER') {
        steps[0].label = 'Purchase Order Shared'
    }
    // added i<10 check here in case there are more history than we are supposed to read
    let count = 0
    for (var i = 0; i < history.length; i++) {
        if(history[i].status === 'Purchase Order Acknowledged' || history[i].status === 'Purchase Order Amended' || history[i].status === 'Purchase Order Cancelled'){
            count = count + 1
            continue
        }
        steps[i - count].type = 'done'
        steps[i - count].completedDate = formatDate(history[i].submittedAt)
        // set the next step as current recursively
        if ((i-count) < 10 && (i-count) !== 7) steps[(i-count) + 1].type = getType(confirmedReceiptDate, 30)  // logic to turn the wheel to amber is if requiredBy is just 30 days away (change this if needed) 
        if ((i-count) === 7) steps[(i-count) + 2].type = getType(confirmedReceiptDate, 30) // check to ignore empty stage
    }
    return { steps }
}

// Function that returns the style of the icon (green, amber, red) based on the grace period from required by date
// TODO modify to add red status, current function only returns green or amber
export const getType = (confirmedReceiptDate: string, gracePeriod: number): string => {
    const confirmedDate = new Date(confirmedReceiptDate)
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() + gracePeriod);
    if (currentDate > confirmedDate) {
        return 'delayed'
    }
    return 'current'
}

// TODO add orderstatus in the input and send default when order is complete
export const getClockColor = (confirmedReceiptDate: string,forecastedDeliveryDate: string) => {
    let numberOfDays = 14
    const confirmedDate = new Date(confirmedReceiptDate)
    const deliveryDate = new Date(forecastedDeliveryDate)
    const confirmedDateAmber = new Date(confirmedDate)
    confirmedDateAmber.setDate(confirmedDateAmber.getDate() + numberOfDays)
    if (deliveryDate <= confirmedDate) {
        return 'default'
    }
    else if(deliveryDate > confirmedDate && deliveryDate <= confirmedDateAmber){
        return 'yellow'
    }
    else if(deliveryDate > confirmedDateAmber){
        return 'red'
    }
}
