import { FC, useState } from 'react'
import Checkbox, { ICheckbox } from '../checkbox/Checkbox'
import { ArrowLeftIcon, ArrowRightIcon } from '../icons/Icons'
import Input from '../input/Input'
import Text from '../text/Text'
import styles from './Filter.module.scss'

export interface IFilter extends React.HTMLAttributes<HTMLDivElement> {
    label: string,
    name: string,
    options: ICheckbox[],
}

const Filter: FC<IFilter> = (props) => {

    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState('')

    const handleBlur = (e: { currentTarget: HTMLDivElement }) => {    
        const target = e.currentTarget  
        requestAnimationFrame(() => {
          if (!target.contains(document.activeElement)) {
            setOpen(false)
          }
        })
    }

    const searchChange = (e: { currentTarget: HTMLInputElement }) => {
        setSearch(e.currentTarget.value)
    }
    
    return (
        <div
            className={styles.container}
            tabIndex={0}
            onBlur={handleBlur}
        >
            <div
                className={styles.header}
                onClick={() => setOpen(!open)}
            >
                {
                    (
                        open
                        &&
                        <><Text type={'medium'} color={'defaultBlue'}>{props.label}</Text><ArrowLeftIcon/></>
                    )
                    ||
                    (
                        !open
                        &&
                        <><Text>{props.label}</Text><ArrowRightIcon/></>
                    )
                }
            </div>
            {
                open
                &&
                <div className={styles.content}>
                    <Input 
                        type={'search'}
                        placeholder={'Search'}
                        onChange={searchChange}
                        style={{width: '100%'}}
                    />
                    <div className={styles.items}>
                        {props.options.map((item, index) => {
                            const label = item.label?.toString()
                            return (
                                label?.toLowerCase().includes(search.toLocaleLowerCase())
                                &&
                                <div key={index} className={styles.item}>
                                    <Checkbox
                                        name={props.name}
                                        onChange={item.onChange}
                                        checked={item.checked}
                                        value={item.value}
                                        label={label}
                                    />
                                </div>
                            )
                        })}
                    </div> 
                </div>
            }
        </div>
    )
}

export default Filter
