import styles from './HomePage.module.scss'
import AppButton from "../../components/appButton/AppButton"
import { useNavigate } from 'react-router-dom'
import kinexis_logo from '../../assets/kinexis_logo.svg'
import intellium_logo from '../../assets/intellium_logo.svg'
import inteli_logo_short from '../../assets/inteli_logo_short.svg'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

const Homepage = () => {

    const navigate = useNavigate()
    const auth = useAuth0()
    const persona = process.env.REACT_APP_PERSONA?.toUpperCase()

    useEffect(() => {
        if (!auth.isAuthenticated && !auth.isLoading) {
            auth.loginWithRedirect()
        }
    }, [])

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h1>Choose where to navigate</h1>
                <br />
                <br />
                <div className={styles.buttons}>
                    <span>
                        <AppButton
                            image={inteli_logo_short}
                            label={'N-tier Supply Chain Management'}
                            onClick={() => { navigate('/overview') }}
                        />
                    </span>
                    {
                        (() => {
                            if (persona?.toUpperCase() === 'BUYER') {
                                return (
                                    <span>
                                        <AppButton
                                            image={kinexis_logo}
                                            label={'Control Tower'}
                                            onClick={() => window.open(process.env.REACT_APP_KINAXIS_URL, '_blank')}
                                        />
                                    </span>
                                )
                            }
                        })()
                    }
                    <span>
                        <AppButton
                            image={intellium_logo}
                            label={'AI Dashboard'}
                            onClick={() => { }}
                        />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Homepage
