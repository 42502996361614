import { FC, useState } from 'react'
import { ArrowDownIcon, ArrowUpIcon } from '../icons/Icons'
import Text from '../text/Text'
import styles from './Select.module.scss'

type Item = {
    label: string,
    value: any,
}

export interface ISelect {
    label?: string,
    placeholder?: string,
    items: Item[],
    required?: boolean,
    disabled?: boolean,
    onChange: (value: string) => void,
}

const Select: FC<ISelect> = (props) => {
    
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState<string | null>()
    
    const handleBlur = (e: { currentTarget: HTMLDivElement }) => {    
        const target = e.currentTarget  
        requestAnimationFrame(() => {
          if (!target.contains(document.activeElement)) {
            setOpen(false)
          }
        })
    }

    const handleSelect = (item: Item) => {
        setSelected(item.label)
        props.onChange(item.value)
        setOpen(false)
    }
    
    return (
        <div className={styles.container}
            tabIndex={0}
            onBlur={handleBlur}
        >
            <div>
                <Text type={'bold'}>{props.label}</Text>
                {
                    props.required
                    && 
                    <Text size={'lg'} color={'error'}>&nbsp;*</Text>
                }
            </div>
            <div
                className={`${styles.header} ${open && styles.open}`}
                onClick={() => props.disabled ? setOpen(false) : setOpen(!open)}
            >
                <Text>{selected ?? props.placeholder}</Text>
                {
                    (
                        open
                        &&
                        <ArrowUpIcon className={styles.icon}/>
                    )
                    ||
                    (
                        !open
                        &&
                        <ArrowDownIcon className={styles.icon}/>
                    )
                }
                
            </div>
            {
                open
                &&
                <div className={styles.items}>
                    {
                        props.items.map((item, key) => (
                            <div key={key}
                                className={styles.item}
                                onClick={() => handleSelect(item)}
                            >
                                {item.label}
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    )
}

export default Select
