import { FC } from 'react'
import { CloseIcon, ExclamationIcon, TickCircleIcon } from '../icons/Icons'
import Link from '../link/Link'
import Modal from '../modal/Modal'
import Text from '../text/Text'
import styles from './Alert.module.scss'

export interface IAlert {
    type?: 'green' | 'yellow' | 'red',
    message: string,
    details? : string,
    open?: boolean,
    onClose: () => void,
}

const Alert: FC<IAlert> = (props) => {

    //default
    const type = props.type ?? 'green'

    return (
        <>
        {
            props.open
            &&
            <Modal>
                <div className={styles.container}>
                    <div className={styles.icon}>
                        {
                            (
                                type === 'green'
                                &&
                                <TickCircleIcon variant={'bold'} color={type}/>
                            )
                            ||
                            (
                                type === 'yellow'
                                &&
                                <ExclamationIcon variant={'bold'} color={type}/>
                            )
                            ||
                            (
                                type === 'red'
                                &&
                                <CloseIcon variant={'bold'} color={type}/>
                            )
                        }
                    </div>
                    <div className={styles.content}>
                        <Text type={'semibold'}>{props.message}</Text>
                        <br/>
                        <Text size={'sm'} color={'medium'}>{props.details}</Text>
                        <br/><br/>
                        <div>
                            <Link onClick={props.onClose}>
                                <Text type={'semibold'} size={'sm'} color={'medium'}>
                                    Dismiss
                                </Text>
                            </Link>
                        </div>
                    </div>
                    <div className={styles.close}>
                        <Link onClick={props.onClose}>
                            <CloseIcon/>
                        </Link>
                    </div>
                </div>
            </Modal>
        }
        </>
    )
}

export default Alert
