import { FC, useState } from 'react'
import { ArrowDownIcon, ArrowUpIcon } from '../icons/Icons'
import styles from './Menu.module.scss'

export interface IMenu extends React.HTMLAttributes<HTMLDivElement> {
    label: React.ReactNode,
}

const Menu: FC<IMenu> = (props) => {
    
    const [open, setOpen] = useState(false)
    
    const handleBlur = (e: { currentTarget: HTMLDivElement }) => {    
        const target = e.currentTarget  
        requestAnimationFrame(() => {
          if (!target.contains(document.activeElement)) {
            setOpen(false)
          }
        })
    }
    
    return (
        <div className={`${props.className} ${styles.container}`}
            tabIndex={0}
            onBlur={handleBlur}
        >
            <div
                className={`${props.className} ${styles.header} ${open && styles.open}`}
                onClick={() => setOpen(!open)}
            >
                {props.label}
                {
                    (
                        open
                        &&
                        <ArrowUpIcon className={styles.icon}/>
                    )
                    ||
                    (
                        !open
                        &&
                        <ArrowDownIcon className={styles.icon}/>
                    )
                }
                
            </div>
            {
                open
                &&
                <div className={styles.items}>
                    {props.children}
                </div>
            }
        </div>
    )
}

export default Menu
