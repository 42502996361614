
import { FC ,useState, useRef, useCallback } from 'react'
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api"

import styles from './Marker.module.scss'

type OfficeNode = {
  id: string;
  field_address: {
    locality: string;
    postal_code: string;
    address_line1: string;
    address_line2: string;
    latitude: number;
    longitude: number;
  };
};
const offices = [
  {
    id: "1",
    field_address: {
      locality: "Gent",
      postal_code: "9000",
      address_line1: "Veldstraat 1",
      address_line2: "a",
      latitude: 51.053589,
      longitude: 3.72242
    }
  },
  {
    id: "2",
    field_address: {
      locality: "Brussel",
      postal_code: "1000",
      address_line1: "Nieuwstraat 1",
      address_line2: "a",
      latitude: 50.85061,
      longitude: 4.35403
    }
  },
  {
    id: "3",
    field_address: {
      locality: "Antwerpen",
      postal_code: "2000",
      address_line1: "Meir 1",
      address_line2: "a",
      latitude: 51.21878,
      longitude: 4.40559
    }
  }
]

interface IMapWithIcons {
  latitude?: number,
  longitude?: number,
}

const MapWithIcons: FC<IMapWithIcons> = (props) => {
  const mapRef = useRef<any>(null);
  const [selectedOffice, setSelectedOffice] = useState<
    OfficeNode | undefined | null
  >(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GMAPS_KEY || ''
  });
  const onLoad = useCallback(

    (mapInstance: { fitBounds: (arg0: google.maps.LatLngBounds) => void; }) => {
      const bounds = new google.maps.LatLngBounds();
      offices.forEach((office) => {
        bounds.extend(
          new google.maps.LatLng(
            office.field_address.latitude,
            office.field_address.longitude
          )
        );
      });
      console.log("here")
      mapRef.current = mapInstance;
      mapInstance.fitBounds(bounds);
    },
    [offices]
  );
  const onClickMarker = (officeId: string) => {
    setSelectedOffice(offices.find((office) => office.id === officeId));
  };

  return (
    <div className={styles.App}>

      {isLoaded && (
        <>
          <GoogleMap
            mapContainerClassName={styles.mapOpen}
            onLoad={onLoad}
          >
            {offices.map((office) => (
              <Marker
                key={office.id}
                onClick={() => onClickMarker(office.id)}
                position={{
                  lat: props.latitude || office.field_address.latitude,
                  lng: props.longitude || office.field_address.longitude
                }}
                icon={{

                  path: "M17.7698 0.933594C13.213 0.938969 8.84445 2.75152 5.62233 5.97363C2.40022 9.19575 0.587669 13.5643 0.582294 18.1211C0.576836 21.8449 1.7932 25.4676 4.04479 28.4336C4.04479 28.4336 4.51354 29.0508 4.59011 29.1398L17.7698 44.6836L30.9557 29.132C31.0245 29.0492 31.4948 28.4336 31.4948 28.4336L31.4964 28.4289C33.7468 25.4642 34.9626 21.8432 34.9573 18.1211C34.9519 13.5643 33.1394 9.19575 29.9172 5.97363C26.6951 2.75152 22.3265 0.938969 17.7698 0.933594ZM17.7698 24.3711C16.5337 24.3711 15.3253 24.0045 14.2975 23.3178C13.2697 22.631 12.4686 21.6549 11.9955 20.5129C11.5225 19.3708 11.3987 18.1142 11.6399 16.9018C11.881 15.6894 12.4763 14.5758 13.3504 13.7017C14.2245 12.8276 15.3381 12.2323 16.5505 11.9912C17.7629 11.75 19.0195 11.8738 20.1616 12.3468C21.3036 12.8199 22.2797 13.621 22.9665 14.6488C23.6532 15.6766 24.0198 16.885 24.0198 18.1211C24.0177 19.7781 23.3586 21.3666 22.1869 22.5382C21.0153 23.7099 19.4268 24.369 17.7698 24.3711Z",
                  strokeColor: "#546FFF",
                  fillColor: "#546FFF",
                  fillOpacity: 1,

                }}
              />
            ))}
            {selectedOffice && (
              <InfoWindow
                position={{
                  lat: selectedOffice.field_address.latitude,
                  lng: selectedOffice.field_address.longitude
                }}
                onCloseClick={() => setSelectedOffice(null)}
              >
                <p>
                  {selectedOffice.field_address.address_line1}{" "}
                  {selectedOffice.field_address.address_line2} -{" "}
                  {selectedOffice.field_address.postal_code}{" "}
                  {selectedOffice.field_address.locality}
                </p>
              </InfoWindow>
            )}
          </GoogleMap>
        </>
      )}
    </div>
  )

}


export default MapWithIcons
