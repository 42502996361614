import { FC } from 'react'
import NotificationsHeader from '../../components/notificationsHeader/NotificationsHeader'
import NotificationsOrder from '../../components/notificationsOrder/NotificationsOrder'
import styles from './Notifications.module.scss'

const NotificationsPage: FC = () => {

    return (
        <div className={styles.container}>
            <NotificationsHeader />
            <div className={styles.content}>
                <NotificationsOrder />
                <NotificationsOrder />
                <NotificationsOrder />
                <NotificationsOrder />
            </div>
        </div>
    )
}

export default NotificationsPage
