import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAxios } from '../../hooks/axioshook'
import Alert from '../../shared_components/alert/Alert'
import Button from '../../shared_components/button/Button'
import Checkbox from '../../shared_components/checkbox/Checkbox'
import FileUploader from '../../shared_components/fileUploader/FileUploader'
import Input from '../../shared_components/input/Input'
import Loading from '../../shared_components/loading/Loading'
import Select from '../../shared_components/select/Select'
import Text from '../../shared_components/text/Text'
import { Design } from '../../types/types'
import styles from './SaveDesignPage.module.scss'

const SaveDesignPage: FC = () => {

    const navigate = useNavigate()
    const [showAlert, setShowAlert] = useState(false)
    const [showError, setShowError] = useState(false)
    const [errorType, setErrorType] = useState('')
    const [form, setForm] = useState<Design>({
        file: null,
        id: '',
        externalId: '',
        name: '',
        supplier: '',
        owner: '',
        material: '',
        alloy: '',
        price: 0,
        requiredCerts: [{description: "HIP CofC"}, {description: "Final CofC Pack"}],
    })

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target
        setForm({...form, [name]: value})
    }

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const newArray = form.requiredCerts.slice(0)
        const index = newArray.findIndex(o => o.description === value)
        if(index > -1 && !e.target.checked) {
            newArray.splice(index, 1)
        }
        if(e.target.checked){
            newArray.push({description: value})
        }
        setForm({...form, requiredCerts: newArray})
    }

    const fileRequest = useAxios()
    const recipeRequest = useAxios()

    // First stage - post the file
    const handleSubmit = () => {
        const formData = new FormData()
        formData.append('file',form.file as File)
        fileRequest.fetchData({
            method: 'POST',
            url: '/attachment',
            data: formData,
        })
    }

    // Second stage - post recipe to database
    useEffect(() => {
        if(!fileRequest.loading && fileRequest.response?.status === 201) {
            recipeRequest.fetchData({
                method: 'POST',
                url: '/recipe',
                data: {
                    externalId: form.externalId,
                    name: form.name,
                    imageAttachmentId: fileRequest.response.data.id,
                    material: form.material,
                    alloy: form.alloy,
                    price: form.price,
                    requiredCerts: form.requiredCerts,
                    supplier: form.supplier,
                }
            })
        }
    }, [fileRequest.loading])

    // Third stage - show alert at the end
    useEffect(() => {
        if(!recipeRequest.loading && recipeRequest.response?.status === 201) {
            setShowAlert(true)
        }
    }, [recipeRequest.loading])

    // error handle
    useEffect(() => {
        // duplicate designID
        if(recipeRequest.error?.response?.status === 500){
            if((recipeRequest.error?.response?.data as AxiosError).message === 'duplicate externalId found'){
                setErrorType('Design ID already exists on the system')
                setShowError(true)
            } else {
                setErrorType((recipeRequest.error?.response?.data as AxiosError).message)
                setShowError(true)
            }
        }
    }, [recipeRequest.error])

    const handleAlertClose = () => {
        setShowAlert(false)
        navigate('/orders/planned')
    }

    const validForm = Boolean(
        form.file && form.externalId && form.name && form.supplier &&
        form.material && form.alloy && form.price
    )

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Text size={'x-lg'} type={'bold'}>Save Design to Chain</Text>
                <Text>
                    Attach a recipe (a CAD Design and Specification) for your order below.
                    Please ensure that the file size is upto 8MB.
                </Text>
                <div className={styles.uploader}>
                    <FileUploader onChange={(file, error)=>setForm({...form, file})}/>
                </div>
                <div className={styles.columns}>
                    <div className={styles.inputs}>
                        <Input name={'externalId'} label={'Design ID'} max={32} onChange={handleOnChange} required/>
                        <Input name={'name'} label={'Name'} max={32} onChange={handleOnChange} required/>
                        <Select
                            label={'Supplier'}
                            placeholder={'- Select a supplier -'}
                            items={[{label: 'Maher', value: 'MAHER'}, {label: 'Third Party', value: 'THIRDPARTY'}]}
                            onChange={value => setForm({...form, supplier: value})}
                            required
                        />
                        <Input name={'material'} label={'Material'} max={32} onChange={handleOnChange} required/>
                        <Input name={'alloy'} label={'Alloy'} max={32} onChange={handleOnChange} required/>
                        <Input type={'number'} min={0} name={'price'} label={'Price'} onChange={handleOnChange} required/>
                    </div>
                    <div className={styles.checkboxes}>
                        <Text type={'medium'}>Required Certificates</Text>
                        <Checkbox value={'HIP CofC'} onChange={handleCheckboxChange} checked disabled>Hot Isostatic Pressing (HIP) CofC</Checkbox>
                        <Checkbox value={'Final CofC Pack'} onChange={handleCheckboxChange} checked disabled>Final CofC Pack</Checkbox>
                        <Checkbox value={'Titanium Powder CofC'} onChange={handleCheckboxChange}>Titanium Powder CofC</Checkbox>
                        <Checkbox value={'Degassing Data'} onChange={handleCheckboxChange}>Degassing Data</Checkbox>
                        <Checkbox value={'Tensile Test CofC'} onChange={handleCheckboxChange}>Tensile Test CofC</Checkbox>
                        <Checkbox value={'Optical Scanning CofC'} onChange={handleCheckboxChange}>Optical Scanning CofC</Checkbox>
                        <Checkbox value={'Ultrasonic NDT CofC'} onChange={handleCheckboxChange}>Ultrasonic NDT (UST) CofC</Checkbox>
                        <Checkbox value={'CMM CofC'} onChange={handleCheckboxChange}>CMM CofC</Checkbox>
                        <Checkbox value={'Dye Penetrant NDT CofC'} onChange={handleCheckboxChange}>Dye Penetrant NDT CofC</Checkbox>
                    </div>
                </div>
                <div className={styles.button}>
                    <Button
                        disabled={!validForm}
                        onClick={handleSubmit}
                    >
                        Save to Chain
                    </Button>
                </div>
            </div>
            <Alert
                message={'Design saved to chain'}
                details={'The design and specification has been shared with your team and the supplier.'}
                onClose={handleAlertClose}
                open={showAlert}
                type={'green'}
            />
            <Alert
                message={'Error'}
                details={errorType}
                onClose={()=> setShowError(false)}
                open={showError}
                type={'red'}
            />
            {
                (fileRequest.loading || recipeRequest.loading)
                &&
                <Loading/>
            }
        </div>
    )
}

export default SaveDesignPage
