import styles from './Bullet.module.scss'
import { FC } from 'react'
import Text from '../text/Text'

interface IBullet extends React.HTMLAttributes<HTMLDivElement> {
    color?: 'light' | 'medium' | 'dark',
    label: string,
}

const Bullet: FC<IBullet> = (props) => {

    // default
    const color = props.color ?? 'light'

    return (
        <div className={`${styles.container} ${styles[color]}`}>
            <Text size={'x-sm'} type={'medium'} color={'inherit'}>{props.label}</Text>
        </div>
    )
}

export default Bullet
