import { FC } from "react"
import styles from './MenuItem.module.scss'

const MenuItem: FC<React.HTMLAttributes<HTMLDivElement>> = (props) => (
    <div className={styles.item}>
        {props.children}
    </div>
)

export default MenuItem
