import { FC, useEffect, useState } from 'react'
import { downloadFile, formatDate, getStage, getStepper } from '../../helper/helper'
import { useAxios } from '../../hooks/axioshook'
import Button from '../../shared_components/button/Button'
import { ArrowDownIcon, ArrowUpIcon, CalendarIcon, ExclamationIcon, QuestionIcon } from '../../shared_components/icons/Icons'
import Loading from '../../shared_components/loading/Loading'
import Popup from '../../shared_components/popup/Popup'
import Step from '../../shared_components/stepper/Step'
import Stepper from '../../shared_components/stepper/Stepper'
import Text from '../../shared_components/text/Text'
import Tooltip from '../../shared_components/tooltip/Tooltip'
import { HistoryResponse, LocationMetadata, Metadata, OrderResponse, PartResponse } from '../../types/types'
import AddFile from '../updateProcess/AddFile'
import AssignJob from '../updateProcess/AssignJob'
import ItemTracking from '../updateProcess/ItemTracking'
import UpdateProcess from '../updateProcess/UpdateProcess'
import styles from './LineDetail.module.scss'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import Modal from '../../shared_components/modal/Modal'
import ThreeWayMatch from '../updateProcess/threeWayMatch'
import MapWithIcons from '../tracking/MapWithIcons'

interface ILineDetails {
    order: OrderResponse,
}

const LineDetail: FC<ILineDetails> = (props) => {

    const parts = useAxios()
    const order = useAxios()
    const locationAttachment = useAxios()
    const partDateUpdate = useAxios()

    const [open, setOpen] = useState(true)
    const [popupOpen, setPopupOpen] = useState(false)
    const [showQR, setShowQR] = useState(false)
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [selectedDay, setSelectedDay] = useState<Date>()
    const [deliveryDate, setDeliveryDate] = useState<Date>();
    const [showMap, setShowMap] = useState(false)

    const [displayButton, setDisplayButton] = useState<{display: Boolean, data: any}>()
    const [content, setContent] = useState<PartResponse>()
    const [history, setHistory] = useState<HistoryResponse>()
    const [text, setText] = useState<string>('')
    const [uploadType, setUploadType] = useState<string>('')
    const persona = process.env.REACT_APP_PERSONA?.toUpperCase()
    const [updateType,setUpdateType] = useState<string>('')
    const [locationAttachmentId, setLocationAttachmentId] = useState<string>('')
    const [locationData, setLocationData] = useState<LocationMetadata>()
    let locationArray: Array<Metadata> = []

    useEffect(() => {
        if(open && parts.response?.status !== 200) {
            parts.fetchData({
                method: 'GET',
                url: `/part/${props.order.parts[0].partId}`,
            })
        }
    }, [open, parts.hasToken])

    useEffect(() => {
        if (parts.response?.status === 200) {
            setContent(parts.response.data)
            setDeliveryDate(new Date(parts.response.data.forecastedDeliveryDate ?? ''))
            const buttonData = getStage(props.order)
            setDisplayButton({
                display: (persona === 'SUPPLIER' && buttonData.pendingAction !== 'Review Purchase Order' && (props.order.status === 'Cancelled' || buttonData.pendingAction !== 'n/a')) || (persona !== 'SUPPLIER' && (buttonData.pendingAction === 'Upload a Goods Receipt Note' || buttonData.pendingAction === 'Complete 3-way match' || props.order.status === 'Cancelled')),
                data: buttonData
            })
            if(parts.response.data.metadata != null){
                for(let i = 0; i < parts.response.data.metadata.length; i++){
                    if(parts.response.data.metadata[i].metadataType == 'location'){
                        locationArray.push(parts.response.data.metadata[i])
                        setLocationAttachmentId(locationArray.slice(-1)[0].attachmentId)
                    }
                }
            }
        }
    }, [parts.loading])

    useEffect(() => {
        if (locationAttachment.response?.status !== 200 && locationAttachmentId != '') {
            locationAttachment.fetchData({
                method: 'GET',
                url: `/attachment/${locationAttachmentId}`
            })
        }
    }, [locationAttachment.hasToken, locationAttachmentId])

    useEffect(() => {
        if (locationAttachment.response?.status === 200) {
            setLocationData(locationAttachment.response.data[0])
            setShowMap(true)
        }
    }, [locationAttachment.loading])


    useEffect(() => {
        if (open && order.response?.status !== 200) {
            order.fetchData({
                method: 'GET',
                url: `/order/${props.order.id}/history`
            })
        }
    }, [open, order.hasToken])

    useEffect(() => {
        if (order.response?.status === 200) {
            setHistory(order.response.data)
        }
    }, [order.loading])

    const handleDownload = (id: string) => {
        downloadFile(order.response, id)
    }

    const handleDateSelection = (updated: boolean) => {
        if(!updated) {
            setSelectedDay(deliveryDate) 
        } else {
            setDeliveryDate(selectedDay)
            partDateUpdate.fetchData({
                method: 'POST',
                url: `/part/${content?.id}/update-delivery-date`,
                data: {
                    forecastedDeliveryDate: selectedDay
                },
            })

        } 
        setShowDatePicker(false)
    }

    let popup
    switch(displayButton?.data.popupType){
        case 'Assign Job':
            popup = 
                <>
                    <AssignJob order={props.order} open={popupOpen} onClose={() => {setPopupOpen(false)}}/>
                </>
            break
        case 'Set Up Item Tracking':
            popup =
                <>
                    <ItemTracking open={popupOpen} 
                        buildId={content?.buildId} 
                        partId={content?.id} 
                        forecastedDeliveryDate={deliveryDate?.toISOString()} 
                        onClose={() => {setPopupOpen(false)}}/>
                </>
            break
        case 'Build Started':
            popup =
                <>
                    <UpdateProcess open={popupOpen} 
                        buildId={content?.buildId} 
                        updateType="Encapsulation Completed" 
                        forecastedDeliveryDate={deliveryDate?.toISOString()} 
                        onClose={() => {setPopupOpen(false)}}/>
                </>
            break
        case 'Upload CofC':
            popup =
                <>
                    <AddFile
                        partId={content?.id ?? ''}
                        recipeId={content?.recipeId ?? ''}
                        final={false}
                        radioHidden={false}
                        open={popupOpen}
                        text={'2.  Use the box to upload the selected file.'}
                        uploadType={'certification'}
                        onClose={() => setPopupOpen(false)}
                    />
                </>
            break
        case 'HIP Completed':
            popup =
                <>
                    <UpdateProcess open={popupOpen} 
                        buildId={content?.buildId} 
                        updateType="Machining and NDT Completed" 
                        forecastedDeliveryDate={deliveryDate?.toISOString()} 
                        onClose={() => {setPopupOpen(false)}}/>
                </>
            break
        case 'Upload Final CofC':
            popup =
                <>
                    <AddFile
                        partId={content?.id ?? ''}
                        recipeId={content?.recipeId ?? ''}
                        final={true}
                        radioHidden={false}
                        open={popupOpen}
                        text={'2.  Use the box to upload the selected file.'}
                        uploadType={'certification'}
                        onClose={() => setPopupOpen(false)}
                    />
                </>
            break
        case 'Upload ASN & Invoice':
            popup = 
                <>
                     <AddFile
                        radioHidden={true}
                        open={popupOpen}
                        text={text}
                        buildId={content?.buildId} 
                        uploadType={uploadType}
                        updateType={updateType}
                        onClose={() => setPopupOpen(false)}
                    />
                </>
            break
        case 'Upload ASN':
            popup = 
                <>
                     <AddFile
                        radioHidden={true}
                        open={popupOpen}
                        text={'Upload the Advance Shipping Note (ASN) for this job'}
                        uploadType={'buildComplete'}
                        buildId={content?.buildId} 
                        onClose={() => setPopupOpen(false)}
                    />
                </>
            break
        case 'Upload Invoice':
            popup = 
                <>
                        <AddFile
                        radioHidden={true}
                        open={popupOpen}
                        text={'Upload the Invoice for this Sales Order'}
                        uploadType={'buildComplete'}
                        buildId={content?.buildId} 
                        onClose={() => setPopupOpen(false)}
                    />
                </>
            break
        case 'Upload GRN':
            popup =
                <>
                    <AddFile
                        radioOptions={['Goods Receipt Note (GRN)','Other (please specify)']}
                        radioHidden={false}
                        open={popupOpen}
                        text={'2.  Use the box to upload the selected file.'}
                        uploadType={'buildProgressUpdate'}
                        updateType={'GRN Uploaded'}
                        buildId={content?.buildId} 
                        onClose={() => setPopupOpen(false)}
                    />
                </>
            break
        case 'Part Received':
            popup =
                <>
                    <ThreeWayMatch
                        response={order.response}
                        buildId={content?.buildId ?? ''}
                        open={popupOpen}
                        onClose={() => setPopupOpen(false)}
                    />
                </>
            break
        default:
    }

    const label = (persona === 'SUPPLIER' ? 'Job' : 'Part')
    return (
        <div className={styles.container}>
            <hr/>
            <div className={styles.header}>
                <div>
                    {( persona === 'BUYER' &&
                        <Text size={'lg'} type={'medium'}>Part Number: {content?.id}</Text>
                    ) ||
                    ( !(persona === 'BUYER') &&
                        <Text size={'lg'} type={'medium'}>Job Number: {content?.build != null ? content?.build.externalId : ''}</Text>
                    )}
                </div>
                <div onClick={() => setOpen(!open)}>
                    {
                        (open && <ArrowUpIcon color={'dark'} />)
                        ||
                        (!open && <ArrowDownIcon color={'dark'} />)
                    }
                </div>
            </div>
            <div className={`${styles.body} ${open && styles.open}`}>
                <div>
                {displayButton?.display && (
                    <div className={styles.updateprocess}>
                        {displayButton.data.actions && displayButton.data.actions.map((item:string) => {
                            let text = ''
                            let updateType = ''
                            if(item === 'Upload ASN'){
                                text = 'Upload the Advance Shipping Note (ASN) for this job'
                                updateType = 'ASN Uploaded'
                            }
                            else if(item === 'Upload Invoice'){
                                text = 'Upload the Invoice for this Sales Order'
                                updateType = 'Invoice Uploaded'
                            }
                            return (<Button type='secondary' onClick={() => {
                                setPopupOpen(true) 
                                setText(text)
                                setUploadType('buildProgressUpdate')
                                setUpdateType(updateType)}}>{item}</Button>)
                        })}
                        {!displayButton.data.actions && props.order.status !== 'Cancelled' && <Button type='secondary' onClick={() => {setPopupOpen(true)}}>{displayButton.data.pendingAction}</Button>}
                        {!displayButton.data.actions && props.order.status === 'Cancelled' && <Button type='cancelled'>{'Order Cancelled'}</Button>}
                    </div>
                    )}
                {popupOpen && (
                    <div>
                        {popup}
                    </div>
                )}
                </div>
                {
                    history?.parts.map((item, key) => (
                        <Stepper key={key}>
                            <>
                                {
                                    getStepper(item.history, persona || 'SUPPLIER', item.requiredBy).steps.filter((item,key) => {
                                        if(key === 8){
                                            return false
                                        }
                                        return true
                                    }).map((step, key) => {
                                        return (<Step key={key} label={step.label} date={step.completedDate} type={step.type} lastStep={step.lastStep} />)
                                    }   
                                    )
                                }
                            </>
                        </Stepper>
                    ))
                }
                <div className={styles.content}>
                    <div>
                        <Popup
                            open={showQR}
                            type={'qrcode'}
                            data={content?.id}
                            onCancel={() => setShowQR(false)}
                        />
                        {showDatePicker && (                            
                            <Modal>
                            <div className={styles.calendar}>
                                <DayPicker
                                    initialFocus={showDatePicker}
                                    mode="single"
                                    defaultMonth={selectedDay}
                                    selected={selectedDay}
                                    onSelect={setSelectedDay}
                                    modifiersClassNames={{
                                        selected: styles.selected,
                                    }}
                                    />
                                <div className={styles.buttons}>
                                    <Button type={'secondary'} onClick={() => handleDateSelection(false)}>Cancel</Button>
                                    <Button type={'primary'} onClick={() => handleDateSelection(true)}>Done</Button>
                                </div>
                            </div>
                            </Modal>                            
                        )}
                        <span className={styles.info}>
                            <Text type={'medium'}>Part Description:</Text>
                            <Text type={'bold'}>{content?.description}</Text>
                            <ExclamationIcon onClick={() => { setShowQR(true) }} />                            
                        </span>
                        <br/>
                        <span className={styles.info}>
                            <Text type={'medium'}>Required Date:</Text>
                            <Text type={'bold'}>{formatDate(content?.requiredBy ?? '')}</Text>                            
                            <Tooltip
                                content={
                                <>
                                    <Text color={'inherit'} type={'regular'} >This is a fixed contractual date that this</Text><br/>
                                    <Text color={'inherit'} type={'regular'} >order is required by.</Text>
                                </>
                                }
                            arrow={'top'}>
                                <QuestionIcon color={'medium'} />
                            </Tooltip>
                        </span>
                        <br/>
                        <span className={styles.info}>
                            <Text type={'medium'}>Confirmed Receipt Date:</Text>
                            <Text type={'bold'}>{formatDate(content?.confirmedReceiptDate ?? '')}</Text>                            
                            <Tooltip
                                content={
                                <>
                                    <Text color={'inherit'} type={'regular'} >This is a fixed date that the supplier can</Text><br/>
                                    <Text color={'inherit'} type={'regular'} >deliver to based on lead times.</Text>
                                </>
                                }
                            arrow={'top'}>
                                <QuestionIcon color={'medium'} />
                            </Tooltip>
                        </span>
                        <br/>
                        <span className={styles.info}>
                            <Text type={'medium'}>Forecasted Delivery Date:</Text>
                            <Text type={'bold'}>{ formatDate(deliveryDate?.toISOString() || '') }</Text>
                            {(persona === 'BUYER' || (content?.build !== undefined && content?.build.asnAttachmentId !== undefined )) &&  
                            <Tooltip
                                content={
                                <>
                                    <Text color={'inherit'} type={'regular'} >This is an editable date that the supplier</Text><br/>
                                    <Text color={'inherit'} type={'regular'} >can update once job has started.</Text>
                                </>
                                }
                            arrow={'top'}>
                                <QuestionIcon color={'medium'} />
                            </Tooltip>
                            ||
                            ( persona !== 'BUYER' && (content?.build === undefined || content?.build.asnAttachmentId === undefined )) &&
                            <Tooltip
                                content={
                                <>
                                    <Text color={'inherit'} type={'regular'} >This is an editable date by the supplier.</Text><br/>
                                    <Text color={'inherit'} type={'regular'} >Click this to open calendar.</Text>
                                </>
                                }
                            arrow={'top'}>
                                <CalendarIcon onClick={() => { setSelectedDay(deliveryDate);setShowDatePicker(true) }}/>
                            </Tooltip>                                
                            }
                        </span>
                        <br/>
                        <span className={styles.info}>
                            <Text type={'medium'}>Quantity:</Text>
                            <Text type={'bold'}>{content?.quantity}</Text>
                        </span>
                        <br/>
                        <span className={styles.info}>
                            <Text type={'medium'}>Price:</Text>
                            <Text type={'bold'}>{content?.price}</Text>
                        </span>
                        <br/>
                        <span className={styles.info}>
                            <Text type={'medium'}>Deliver to Address:</Text>
                            <Text type={'bold'}>{content?.deliveryAddress}</Text>
                        </span>
                        <br/>
                        <br/>
                        {
                            showMap == true && (
                                <div className={styles.map}>
                                    <MapWithIcons longitude={locationData?.Longitude} latitude={locationData?.Latitude}></MapWithIcons>
                                </div>
                            )
                        }
                    </div>
                    <div className={styles.files}>
                        <Text type={'medium'}>Files:</Text>
                        <span>
                            {
                                content?.certifications.map((item, key) => (
                                    <Button
                                        key={key} type={'download'}
                                        onClick={() => handleDownload(item.certificationAttachmentId)}
                                        disabled={item.certificationAttachmentId === undefined}
                                    >
                                        {item.description}
                                    </Button>
                                ))
                            }
                            {
                                content?.attachments.map((item, key) => (
                                    <Button
                                        key={key} type={'download'}
                                        onClick={() => handleDownload(item.attachmentId)}
                                    >
                                        {item.description}
                                    </Button>
                                ))
                            }
                        </span>
                    </div>
                </div>
            </div>
                {
                    (showMap == true && open) && (
                    <>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </>
                    ) ||
                    <>
                    </>
                }
            <hr/>
            {
                (order.loading || parts.loading || partDateUpdate.loading)
                &&
                <Loading />
            }
        </div>
    )
}

export default LineDetail
