import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ActiveHeader from '../../components/activeHeader/ActiveHeader'
import { formatDate, getStage } from '../../helper/helper'
import { useAxios } from '../../hooks/axioshook'
import { ExclamationIcon } from '../../shared_components/icons/Icons'
import Loading from '../../shared_components/loading/Loading'
import Table from '../../shared_components/table/Table'
import Text from '../../shared_components/text/Text'
import Tooltip from '../../shared_components/tooltip/Tooltip'
import { OrderResponse } from '../../types/types'
import styles from './ActivePOPage.module.scss'

const ActivePOPage: FC = () => {

    const persona = process.env.REACT_APP_PERSONA?.toUpperCase()
    
    const [columns] = useState(() => {
        if(persona === 'BUYER'){
            return ['PO Number', 'Part Number', 'Supplier', 'Current Stage', 'Forecasted Delivery']
        } else if(persona === 'SUPPLIER'){
            return ['Sales Order #', 'Job #', 'Pending Action', 'Buyer','Current Stage', 'Forecasted Delivery']
        } else {
            return ['PO Number', 'Part Number', 'Supplier', 'Current Stage', 'Forecasted Delivery']
        }
    })

    const [content, setContent] = useState([])
    const [filter, setFilter] = useState('')
    const [count, setCount] = useState(0)
    const navigate = useNavigate()
    const orders = useAxios()
    const orderCount = useAxios()
    let tooltipValue

    useEffect(() => {
        orders.fetchData({
            method: 'GET',
            url: '/order?limit=10&page=1'
        })
    }, [orders.hasToken])


    useEffect(() => {
        orderCount.fetchData({
            method: 'GET',
            url: '/order/count'
        })
    }, [orderCount.hasToken])

    useEffect(() => {
        if(orderCount.response?.data && !orderCount.loading){
            setCount(orderCount.response.data.count)
        }
    },[orderCount.loading])

    useEffect(() => {
        if (orders.response?.data && !orders.loading) {
            if (persona !== 'SUPPLIER'){
                setContent(orders.response?.data.map((order: OrderResponse, key: number) => {
                    switch(getStage(order).tooltipUpdate){
                        case 'Exception':
                            tooltipValue = {
                                value: order.externalId,
                                display: 
                                    <>
                                        <div>
                                            <Tooltip
                                                content={
                                                    <>
                                                        <Text color={'inherit'} type={'semibold'} >Purchase Order Amendment</Text>
                                                        <br/>
                                                        <Text color={'inherit'} type={'regular'} >The supplier has responded with required</Text>
                                                        <Text color={'inherit'} type={'regular'} >amendments to your Purchase Order.</Text>
                                                    </>
                                                }
                                            arrow={'left'}
                                            > 
                                                <ExclamationIcon variant={'boldLarge'}/>
                                            </Tooltip>
                                        </div>
                                        <Text>{order.externalId}</Text>
                                    </>
                            }
                            break
                        case 'Invoice Received':
                            tooltipValue = {
                                value: order.externalId,
                                display: 
                                    <>
                                        <div>
                                            <Tooltip
                                                content={
                                                    <>
                                                        <Text color={'inherit'} type={'semibold'} >Upload GRN</Text>
                                                        <br/>
                                                        <Text color={'inherit'} type={'regular'} >Open this order and upload GRN once the</Text>
                                                        <Text color={'inherit'} type={'regular'} >order has been received.</Text>
                                                    </>
                                                }
                                            arrow={'left'}
                                            > 
                                                <ExclamationIcon variant={'boldLarge'}/>
                                            </Tooltip>
                                        </div>
                                        <Text>{order.externalId}</Text>
                                    </>
                            }
                            break
                        case 'Part Received':
                            tooltipValue = {
                                value: order.externalId,
                                display: 
                                    <>
                                        <div>
                                            <Tooltip
                                                content={
                                                    <>
                                                        <Text color={'inherit'} type={'semibold'} >3-Way Match Pending</Text>
                                                        <br/>
                                                        <Text color={'inherit'} type={'regular'} >Open this order to complete a 3-way match</Text>
                                                        <Text color={'inherit'} type={'regular'} >and release it for payment.</Text>
                                                    </>
                                                }
                                            arrow={'left'}
                                            > 
                                                <ExclamationIcon variant={'boldLarge'}/>
                                            </Tooltip>
                                        </div>
                                        <Text>{order.externalId}</Text>
                                    </>
                            }
                            break
                        default: 
                            tooltipValue = {
                                value: order.externalId
                            }
                            break
                    }

                    return [
                        { value: order.id, hidden: true},
                        tooltipValue,
                        { value: order.parts[0].partId },
                        { value: order.supplier },
                        { value: <Text>{getStage(order).stage}<br/>{getStage(order).updatedOn}</Text> },
                        { value: formatDate(order.parts[0].forecastedDeliveryDate) },
                    ]
                }))
            } 
            if (persona === 'SUPPLIER'){
                setContent(orders.response?.data.map((order: OrderResponse, key: number) => {
                    return [
                        { value: order.id, hidden: true},
                        { value: order.externalId },
                        { value: order.parts[0].buildExternalId || '-'},
                        { value: getStage(order).pendingAction },
                        { value: order.buyer },
                        { value: <Text>{getStage(order).stage}<br/>{getStage(order).updatedOn}</Text> },
                        { value: formatDate(order.parts[0].forecastedDeliveryDate) },
                    ]
                }))
            }
        }
    }, [orders.loading])

    const handleRowClick = (item: {value: string | number}[]) => {
        navigate(`/orders/${item[1].value}`)
    }

    const handlePaginationClick = async (page: number,rowsPerPage: number) => {
        await orders.fetchData({
            method: 'GET',
            url: `/order?limit=${rowsPerPage}&page=${page}`
        })
    }

    const handleRowsPerPageClick = async (rowsPerPage: number,page: number) => {
        await orders.fetchData({
            method: 'GET',
            url: `/order?limit=${rowsPerPage}&page=${page}`
        })
    }
    return (
        <div className={styles.container}>
            <ActiveHeader onChange={value => setFilter(value)} />
            <br />
            <br />
            <div className={styles.content}>
                <Table
                    header={columns}
                    data={content}
                    filter={{ query: filter, indexes: [0, 1] }}
                    onRowClick={index => handleRowClick(index)}
                    onPaginationClick={(page,rowsPerPage) => handlePaginationClick(page,rowsPerPage)}
                    totalCount={count}
                    onRowsPerPageClick={(rowsPerPage,page) => handleRowsPerPageClick(rowsPerPage,page)}
                />
            </div>
            {
                orders.loading
                &&
                <Loading />
            }
        </div>
    )
}

export default ActivePOPage
