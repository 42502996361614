import styles from './Navigation.module.scss'
import { FC } from "react";
import Logo from '../../shared_components/logo/Logo';
import NavBar from '../navBar/NavBar';
import { Outlet, useLocation } from 'react-router-dom';
import SideBar from '../sideBar/SideBar';

const Navigation: FC = () => {

    const location = useLocation()

    if(location.pathname === '/') {
        return (
            <>
                <Outlet/>
            </>
        )
    }

    return (
        <div className={styles.container}>
            <div className={styles.logo}>
                <Logo/>
            </div>
            
            <div className={styles.navbar}>
                <NavBar/>
            </div>

            <div className={styles.sidebar}>
                <SideBar/>
            </div>
            
            <Outlet/>
        </div>
    )
}

export default Navigation
