import { FC } from 'react'
import Checkbox from '../../shared_components/checkbox/Checkbox'
import { DocumentIcon } from '../../shared_components/icons/Icons'
import Menu from '../../shared_components/menu/Menu'
import Text from '../../shared_components/text/Text'
import styles from './NotificationsOrder.module.scss'

const NotificationsOrder: FC = () => (
    <div className={styles.container}>
        <div className={styles.header}>
            <Text size={'lg'} type={'semibold'}>PO# 12345678</Text>
            <Menu label={'View all notifications'}></Menu>
        </div>
        <div className={styles.details}>
            <div>
                <DocumentIcon variant={'bold'}/>
                <Text>Purchase Order Accepted</Text>
            </div>
            <div>
                <Text size={'x-sm'} color={'medium'}>30 min ago</Text>
                <Checkbox />
            </div>
        </div>
    </div>
)

export default NotificationsOrder
