import { FC, useEffect } from 'react'
import { useAxios } from "../../hooks/axioshook"
import Button from '../../shared_components/button/Button'
import { CloseIcon } from '../../shared_components/icons/Icons'
import Link from '../../shared_components/link/Link'
import Modal from '../../shared_components/modal/Modal'
import Text from '../../shared_components/text/Text'
import styles from './UpdateProcess.module.scss'
import Loading from '../../shared_components/loading/Loading'
import { QRCodeSVG } from 'qrcode.react'

interface IItemTracking {
    open?: boolean,
    buildId?: string,
    partId?: string,
    forecastedDeliveryDate?: string
    onClose: () => void
}

const ItemTracking: FC<IItemTracking> = (props) => {

    const updateJob = useAxios()
    const handleSubmit = () => {
        // Send progress update for build
        updateJob.fetchData({
            method: 'POST',
            url: `/build/${props.buildId}/progress-update`,
            data: {
                updateType: "Lime Tracking ID Attached",
                completionEstimate: props.forecastedDeliveryDate
            },
        })
    }
    useEffect(() => {
        if(updateJob.response?.status === 201) {
            window.location.reload();
        }
    }, [updateJob.loading])

    return (
        <>
        {
            props.open
            &&
            <Modal>
                <div className={styles.container}>
                    <Link 
                        className={styles.close}
                        onClick={props.onClose}
                    >
                        <CloseIcon color={'dark'}/>
                    </Link>
                    <Text size={'x-lg'} type={'bold'}>Set Up Item Tracking</Text>
                    <div className={styles.type}>
                        <Text>1.  Scan the QR code with the Lime App to setup item tracking.</Text>
                        <div className={styles.qrcode}>
                        <QRCodeSVG value={props.partId || ''} />
                        </div>
                        <Text>2.  Once completed, confirm with the button below.</Text>
                    </div>
                    <div>                   
                        <Button 
                        onClick={handleSubmit} 
                        className={styles.button}
                        >
                            Confirm Item Tracking
                        </Button>
                    </div>
                    {
                        (updateJob.loading)
                        &&
                        <Loading />
                    }
                </div>
            </Modal>
        }
        </>
    )
}

export default ItemTracking
