import { FC, useState, useEffect } from 'react'
import { useAxios } from "../../hooks/axioshook"
import Button from '../../shared_components/button/Button'
import { CloseIcon } from '../../shared_components/icons/Icons'
import Input from '../../shared_components/input/Input'
import Link from '../../shared_components/link/Link'
import Modal from '../../shared_components/modal/Modal'
import Text from '../../shared_components/text/Text'
import styles from './UpdateProcess.module.scss'
import Loading from '../../shared_components/loading/Loading'
import { OrderResponse } from '../../types/types'

interface IAssignJob {
    open?: boolean,
    onClose: () => void,
    order: OrderResponse
}

const AssignJob: FC<IAssignJob> = (props) => {

    const [value, setValue] = useState('')

    const date = new Date()

    const createJob = useAxios()
    const scheduleJob = useAxios()
    const startJob = useAxios()

    const handleSubmit = () => {
        // First stage
        createJob.fetchData({
            method: 'POST',
            url: '/build',
            data: {
                externalId: value,
                partIds: [
                    props.order.parts[0].partId
                ],
                completionEstimate: props.order.parts[0].forecastedDeliveryDate
            },
        })
    }

    // Second stage 
    useEffect(() => {
        if(!createJob.loading && createJob.response?.status === 201) {
            scheduleJob.fetchData({
                method: 'POST',
                url: `/build/${createJob.response.data.id}/schedule`,
                data: {
                    completionEstimate: props.order.parts[0].forecastedDeliveryDate
                }
            })
        }
    }, [createJob.loading])

    // Third stage 
    useEffect(() => {
        if(!scheduleJob.loading && scheduleJob.response?.status === 201) {
            startJob.fetchData({
                method: 'POST',
                url: `/build/${createJob.response?.data.id}/start`,
                data: {
                    startedAt: date.toISOString(),
                    completionEstimate: props.order.parts[0].forecastedDeliveryDate
                }
            })
        }
    }, [scheduleJob.loading])

    // Forth stage
    useEffect(() => {
        if(!startJob.loading && startJob.response?.status === 201) {
            window.location.reload();
        }
    }, [startJob.loading])

    return (
        <>
        {
            props.open
            &&
            <Modal>
                <div className={styles.container}>
                    <Link 
                        className={styles.close}
                        onClick={props.onClose}
                    >
                        <CloseIcon color={'dark'}/>
                    </Link>
                    <Text size={'x-lg'} type={'bold'}>Assign Job</Text>
                    <div className={styles.type}>
                        <Text>Please enter the job number to confirm that the manufacturing process has begun.</Text>
                    </div>
                    <div className={styles.input}>
                        <Input
                                type={'text'}
                                placeholder={'Enter Job Number Here'}
                                onChange={(e) => {setValue(e.target.value)}}
                            />
                    </div>
                    <div>                   
                        <Button 
                        onClick={props.onClose} 
                        className={styles.button} 
                        type='secondary'
                        >
                            Cancel
                        </Button>
                        <Button 
                        onClick={handleSubmit} 
                        className={styles.button}
                        disabled={value === ''}
                        >
                            Update
                        </Button>
                    </div>
                    {
                        (createJob.loading || scheduleJob.loading || startJob.loading)
                        &&
                        <Loading />
                    }
                </div>
            </Modal>
        }
        </>
    )
}

export default AssignJob
