import { FC, useEffect, useState } from 'react'
import { useAxios } from '../../hooks/axioshook'
import Bullet from '../../shared_components/bullet/Bullet'
import { ArrowDownIcon, ArrowUpIcon, ClockIcon } from '../../shared_components/icons/Icons'
import Link from '../../shared_components/link/Link'
import Step from '../../shared_components/stepper/Step'
import Stepper from '../../shared_components/stepper/Stepper'
import Text from '../../shared_components/text/Text'
import styles from './OverviewOrder.module.scss'
import { HistoryResponse } from "../../types/types"
import { getClockColor, getStepper } from '../../helper/helper'

interface IOverviewOrder {
    id: string,
    number: string,
    status: string,
    lines: number,
    progress: number,
    tag?: string,
}

const OverviewOrder: FC<IOverviewOrder> = (props) => {

    const history = useAxios()
    const [open, setOpen] = useState(false)
    const [content, setContent] = useState<HistoryResponse>()

    const persona = process.env.REACT_APP_PERSONA?.toUpperCase()
    let ordersText: string
    let lIHeaderText: string
    let lIText: string
    let lIStepOne: string

    useEffect(() => {
        if (open && history.response?.status !== 200) {
            history.fetchData({
                method: 'GET',
                url: `/order/${props.id}/history`
            })
        }
    }, [open, history.hasToken])

    useEffect(() => {
        if (history.response?.status === 200) {
            setContent(history.response.data)
        }
    }, [history.loading])

    if (persona === 'BUYER') {
        ordersText = 'Purchase Order'
        lIHeaderText = 'Lines'
        lIText = 'Part'
        lIStepOne = 'Purchase Order Shared'
    } else if (persona === 'SUPPLIER') {
        ordersText = 'Sales Order'
        lIHeaderText = 'Jobs'
        lIText = 'Job'
        lIStepOne = 'Purchase Order Received'
    } else {
        ordersText = 'Sales Order'
        lIHeaderText = 'Jobs'
        lIText = 'Job'
        lIStepOne = 'Purchase Order Received'
    }

    return (
        <div className={styles.container}>
            <div className={styles.order}>
                <div className={styles.header}>
                    <Text type={'medium'}>{ordersText}: {props.number}</Text>
                    <span>
                        {
                            props.tag &&
                            <Bullet
                                label={props.tag}
                                color={
                                    props.progress > 80 ? 'dark' :
                                        props.progress > 20 ? 'medium' : 'light'
                                }
                            />
                        }
                        {
                            (
                                open &&
                                <Link onClick={() => setOpen(false)}><ArrowUpIcon color={'dark'} /></Link>
                            ) || (
                                !open &&
                                <Link onClick={() => setOpen(true)}><ArrowDownIcon color={'dark'} /></Link>
                            )
                        }
                    </span>
                </div>
                <div className={styles.bar}>
                    <div className={styles.green} style={{ width: `${props.progress}%` }} />
                </div>
                <div className={styles.footer}>
                    <Text size={'x-sm'}>{lIHeaderText}: {props.lines}</Text>
                    <Text size={'x-sm'}>Progress: {props.progress}%</Text>
                </div>
            </div>
            {
                content?.parts.map((item, key) => {
                    var itemId: string = ''
                    if (persona === 'BUYER') {
                        itemId = item.id.substring(item.id.lastIndexOf('-') + 1)
                    } else {
                        itemId = item.buildExternalId
                    }
                    return (
                        <div key={key} className={`${styles.content} ${open && styles.open}`}>
                            <div className={styles.label}>
                            { (props.status !== 'Completed') && 
                                <div><ClockIcon variant={'bold'} color={getClockColor(item.confirmedReceiptDate,item.forecastedDeliveryDate)}/>&nbsp;&nbsp;</div>
                            }
                            <Text type={'medium'}>{lIText} Number: {itemId}</Text>
                            </div>
                            <Stepper>
                                <>
                                    {
                                        getStepper(item.history, persona || 'SUPPLIER', item.confirmedReceiptDate).steps.filter((item, key) => {
                                            if(key === 8){
                                                return false
                                            }
                                            return true
                                        }).map((step, key) => (
                                            <Step key={key} label={step.label} date={step.completedDate} type={step.type} lastStep={step.lastStep} />   
                                        ))
                                    }
                                </>
                            </Stepper>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default OverviewOrder
