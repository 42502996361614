import { FC, Fragment, useEffect, useState } from "react"
import styles from './OverviewPage.module.scss'
import OverviewHeader from "../../components/overviewHeader/OverviewHeader"
import Text from "../../shared_components/text/Text"
import Input from "../../shared_components/input/Input"
import OverviewOrder from "../../components/overviewOrder/OverviewOrder"
import { useAxios } from "../../hooks/axioshook"
import { OrderResponse, SummaryResponse } from "../../types/types"
import Loading from "../../shared_components/loading/Loading"
import { getProgress } from "../../helper/helper"
import IconMenu from '../../shared_components/iconMenu/IconMenu'
import { ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon, ArrowUpIcon } from "../../shared_components/icons/Icons";
import Link from "../../shared_components/link/Link";
import MenuItem from "../../shared_components/menu/MenuItem";

const OverviewPage: FC = () => {

    //TODO update the drop down text also on the line items
    const persona = process.env.REACT_APP_PERSONA?.toUpperCase()
    let ordersText : string
    let searchBarText : string

    const summary = useAxios()
    const orders = useAxios()

    const [filter, setFilter] = useState<OrderResponse[]>()
    const [header, setHeader] = useState<SummaryResponse>()
    const [content, setContent] = useState<OrderResponse[]>()
    const orderCount = useAxios()
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [totalCount, setTotalCount] = useState(0)
    const [totalPages,setTotalPages] = useState(0)
    const first = (page - 1) * rowsPerPage
    const chunk = first + rowsPerPage

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(content?.filter((item) => {
            return item.externalId.includes(e.target.value)
        }))
    }

    useEffect(() => {
        summary.fetchData({
            method: 'GET',
            url: '/order/summary'
        })
    }, [summary.hasToken])

    useEffect(() => {
        if(summary.response?.status === 200) {
            setHeader(summary.response.data)
        }
    },[summary.loading])

    useEffect(() => {
        orders.fetchData({
            method: 'GET',
            url: '/order?limit=10&page=1'
        })
    }, [orders.hasToken])

    useEffect(() => {
        if(orders.response?.status === 200) {
            setContent(orders.response.data)
            setFilter(orders.response.data)
        }
    },[orders.loading])

    useEffect(() => {
        orderCount.fetchData({
            method: 'GET',
            url: '/order/count'
        })
    }, [orderCount.hasToken])

    useEffect(() => {
        if(orderCount.response?.data && !orderCount.loading){
            setTotalPages(orderCount.response?.data.count/ rowsPerPage)
            setTotalCount(orderCount.response?.data.count)
        }
    },[orderCount.loading])

    if(persona === 'BUYER') {
            ordersText = 'PURCHASE ORDERS'
            searchBarText = 'Purchase Order'
    } else if (persona === 'SUPPLIER') {
            ordersText = 'SALES ORDERS'
            searchBarText = 'Sales Order'
    } else {
            ordersText = 'PURCHASE ORDERS'
            searchBarText = 'Purchase Order'
    }

    const handleRowsPerPage = (rows: number) => {
        setRowsPerPage(rows)
        setPage(1)
    }

    useEffect(() => {
        const handlePaginationClick = async (page: number,rowsPerPage: number) => {
            await orders.fetchData({
                method: 'GET',
                url: `/order?limit=${rowsPerPage}&page=${page}`
            })
        }
        handlePaginationClick(page, rowsPerPage)
    },[page])

    useEffect(() => {
        const handleRowsPerPageClick = async (rowsPerPage: number,page: number) => {
            await orders.fetchData({
                method: 'GET',
                url: `/order?limit=${rowsPerPage}&page=${page}`
            })
        }
        handleRowsPerPageClick(rowsPerPage, page)
    },[rowsPerPage])

    return (
        <div className={styles.container}>
            <OverviewHeader
                partsOrdered={header?.parts ?? 0}
                stats={[
                    header?.design ?? 0,
                    header?.order ?? 0,
                    header?.manufacturing ?? 0,
                    header?.ship ?? 0,
                ]}
            />
            <br />
            <div className={styles.orders}>
                <div className={styles.ordersHeader}>
                    <Text size={'lg'} type={'bold'}>
                        {totalCount} ACTIVE {ordersText}
                    </Text>
                    <Input
                        type={'search'}
                        placeholder={'Search for ' + searchBarText +  ' Number'}
                        onChange={handleSearch}
                    />
                </div>
                <div className={styles.content}>
                    {
                        filter && filter.map((item, key) => {
                            const progress = getProgress(item)
                            return (
                                <Fragment key={key}>
                                    <OverviewOrder
                                        id={item.id}
                                        number={item.externalId}
                                        status={item.status}
                                        lines={item.parts.length}
                                        progress={progress.progress}
                                        tag={progress.stage}
                                    />
                                </Fragment>
                            )
                        })
                    }
                </div>
            </div>
            {
                // Pagination controls
                totalCount > 0
                &&
                <div className={styles.footer}>
                    <span className={styles.controls}>
                        <Text color={'medium'} >Rows per page: {rowsPerPage}&nbsp;</Text>
                        <IconMenu
                            icon={<ArrowDownIcon color={'medium'}/>}
                            position={'left'}
                        >
                            <MenuItem>
                                <Link onClick={() => handleRowsPerPage(10)}>
                                    <Text color={'inherit'}>10 rows per page</Text>
                                </Link>
                            </MenuItem>
                            <MenuItem>
                                <Link onClick={() => handleRowsPerPage(25)}>
                                    <Text color={'inherit'}>25 rows per page</Text>
                                </Link>
                            </MenuItem>
                            <MenuItem>
                                <Link onClick={() => handleRowsPerPage(50)}>
                                    <Text color={'inherit'}>50 rows per page</Text>
                                </Link>
                            </MenuItem>
                        </IconMenu>
                        
                    </span>
                    <span className={styles.controls}>
                        <Text color={'medium'} >
                            {first + 1} - {chunk < totalCount ? chunk : totalCount} of {totalCount}
                        </Text>
                        <Link onClick={() => setPage(prev => prev > 1 ? prev - 1 : prev)}>
                            <ArrowLeftIcon color={'medium'} />
                        </Link>
                        <Link onClick={() => setPage(prev => prev < totalPages ? prev + 1 : prev)}>
                            <ArrowRightIcon color={'medium'} />
                        </Link>
                    </span>
                </div>
            }
            {
                (summary.loading || orders.loading)
                &&
                <Loading />
            }
        </div>
    )
}

export default OverviewPage
