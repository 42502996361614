import styles from './FileUploader.module.scss'
import { FC, useRef, useState } from "react"
import Text from '../text/Text';
import { BinIcon, DocumentIcon, UploadIcon } from '../icons/Icons';
import Link from '../link/Link';

interface IFileUploader {
    onChange: (file: File | null, error: string) => void,
}

const FileUploader: FC<IFileUploader> = (props) => {

    const uploadArea = useRef<HTMLDivElement>(null)
    const[file, setFile] = useState<File | null>(null)

    const dragToggle = () => {
        uploadArea.current?.classList.toggle(styles.dragover)
    }

    const fileChange = (e: { target: HTMLInputElement }) => {
        uploadArea.current?.classList.remove(styles.dragover)
        const newFile = e.target.files ? e.target.files[0] : null

        if(newFile && newFile.size < 2.5e7) {
            props.onChange(newFile, '')
            setFile(newFile)
        }
        else {
            props.onChange(null, 'File is bigger than 2.5 Mbytes.')
            setFile(null)
        }
    }

    const binClick = () => {
        setFile(null)
        props.onChange(null, '')
    }

    return (
        <>
        {
            (

                !file
                &&
                <div
                    ref={uploadArea}
                    className={styles.uploader}
                    onDragEnter={dragToggle}
                    onDragLeave={dragToggle}
                >
                    <input
                        type='file'
                        onChange={fileChange}
                        accept={'.pdf, image/*'}
                    />
                    <UploadIcon color={'dark'} />
                    <br/>
                    <div>
                        <Text>Drag and drop or <u>browse files</u></Text>
                    </div>
                </div>
            )
            ||
            (
                file
                &&
                <div className={styles.file}>
                    <DocumentIcon variant={'bold'} color={'medium'}/>
                    <div>
                        <Text size={'sm'}>{file.name}</Text><br/>
                        <Text size={'x-sm'}>{Math.floor(file.size / 1024)} KB</Text>
                    </div>
                    <Link onClick={binClick}><BinIcon color={'red'}/></Link>
                </div>
            )
        }
        </>
    )
}

export default FileUploader