import styles from './Logo.module.scss'

const logo = process.env.REACT_APP_LOGO

const Logo = () => (
    <div className={styles.logo}>
        <img alt='logo' src={require(`../../assets/logos/${logo}`)} />
    </div>
)

export default Logo
