import { useState } from 'react'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { useAuth0 } from '@auth0/auth0-react'

export const useAxios = () => {

    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

    const [response, setResponse] = useState<AxiosResponse>()
    const [error, setError] = useState<AxiosError>()
    const [loading, setLoading] = useState(false)
    const [hasToken, setHasToken] = useState(false)
    const { isAuthenticated, getAccessTokenSilently } = useAuth0()

    const fetchData = async (params: AxiosRequestConfig) => {
        setLoading(true)
        try {
            const accessToken = await getAccessTokenSilently()
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
            setHasToken(true)
            const result = await axios.request(params)
            setResponse(result)
        }
        catch (e) {
            const err = e as AxiosError
            setError(err)
            console.log('Endpoint:', err)
            if (err.response?.status === 401 && isAuthenticated) {
                fetchToken()
            }
        }
        finally {
            setLoading(false)
        }
    }

    const fetchToken = async () => {
        try {
            const accessToken = await getAccessTokenSilently()
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
            setHasToken(true)
        }
        catch (e) {
            const err = e as AxiosError
            console.log('Token:', err)
        }
    }
    return { response, error, loading, fetchData, hasToken }
}
