import { FC } from 'react'
import styles from './PlannedHeader.module.scss'
import background from '../../assets/images/planned_bg.jpg'
import Text from '../../shared_components/text/Text'
import Input from '../../shared_components/input/Input'
import Button from '../../shared_components/button/Button'
import { useNavigate } from 'react-router-dom'

interface IPlannedHeader {
    onChange: (value: string) => void,
}

const PlannedHeader: FC<IPlannedHeader> = (props) => {

    const navigate = useNavigate()

    return (
        <div className={styles.container}>
            <div className={styles.image}>
                <img src={background}/>
            </div>
            <div className={styles.content}>
                <Text size={'x-lg'} color={'inherit'}>Planned Purchase Orders</Text>
                <div>
                    <div>
                        <Input
                            className={styles.search} type={'search'}
                            placeholder={'Search for Design ID or Name'}
                            onChange={e => props.onChange(e.target.value)}
                        />
                    </div>
                    <div>
                        <Button onClick={() => navigate('./add-new-design')}>Add New Design</Button>
                        <Button onClick={() => navigate('./issue-purchase-order')}>Issue Purchase Order</Button>
                    </div>  
                </div>
            </div>
        </div>
    )
}

export default PlannedHeader
