import { FC } from 'react'
import styles from './ActiveHeader.module.scss'
import background from '../../assets/images/active_bg.jpg'
import Text from '../../shared_components/text/Text'
import Input from '../../shared_components/input/Input'
import Menu from '../../shared_components/menu/Menu'
import MenuItem from '../../shared_components/menu/MenuItem'
import Filter from '../../shared_components/filter/Filter'

const supplierOptions = [
    {label: 'Maher', value: 'maher'},
    {label: 'Supplier A', value: 'a'},
    {label: 'Supplier B', value: 'b'},
    {label: 'Supplier C', value: 'c'},
]

const typeOptions = [
    {label: '???', value: 'none'},
]

interface IActiveHeader {
    onChange: (value: string) => void,
}

const persona = process.env.REACT_APP_PERSONA?.toUpperCase()
let ordersText : string
let searchTextPrefix : string
let searchTextSuffix : string
let filterText : string

const ActiveHeader: FC<IActiveHeader> = (props) => {

    if (persona === 'BUYER') {
        ordersText = 'Active Purchase Orders'
        searchTextPrefix = 'Purchase Order'
        searchTextSuffix = 'Part'
        filterText = 'Supplier'
    } else if (persona === 'SUPPLIER') {
        ordersText = 'Sales Orders'
        searchTextPrefix = 'Sales Order'
        searchTextSuffix = 'Job'
        filterText = 'Buyer'
    } else {
        ordersText = 'Active Purchase Orders'
        searchTextPrefix = 'Purchase Order'
        searchTextSuffix = 'Part'
        filterText = 'Supplier'
    }

    return(
        <div className={styles.container}>
            <div className={styles.image}>
                <img src={background}/>
            </div>
            <div className={styles.filter}>
                <Text size={'x-lg'} color={'inherit'}>{ordersText}</Text>
                <div>
                    <div>
                        <Input
                            className={styles.search}
                            type={'search'}
                            placeholder={'Search for ' + searchTextPrefix + ' or ' + searchTextSuffix + ' Number'}
                            onChange={e => props.onChange(e.target.value)}
                        />
                        <Menu className={styles.menu} label={'Filter Search'}>
                            <MenuItem>
                                <Filter name={filterText.toLowerCase()} label={filterText} options={supplierOptions}/>
                            </MenuItem>
                            <MenuItem>
                                <Filter name={'type'} label={'Notification Type'} options={typeOptions}/>
                            </MenuItem>
                        </Menu>
                    </div>
                    <div>
                        <Menu className={styles.menu} label={'Customise Columns'}>
                            <MenuItem>
                                Placeholder
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActiveHeader
