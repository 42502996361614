import { FC, ReactNode, useEffect, useState } from "react";
import IconMenu from "../iconMenu/IconMenu";
import { ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon, ArrowUpIcon } from "../icons/Icons";
import Link from "../link/Link";
import MenuItem from "../menu/MenuItem";
import Text from "../text/Text";
import styles from './Table.module.scss'

// data must be an array of {value, display?, hidden?} array.
// Use hidden to forward the data without displaying it.
// filter has the query string and an array of indexes (for each item in data)
// that will be searched on respective value.
interface ITable extends React.TableHTMLAttributes<HTMLTableElement> {
    header: string[],
    data: {value: string | number, display?: ReactNode, hidden?: boolean}[][],
    filter?: {query: string, indexes: number[]},
    onRowClick?: (item: any,key: number) => void,
    onPaginationClick?: (page: number, rowsPerPage: number) => void,
    totalCount: number,
    onRowsPerPageClick?: (rowsPerPage:number, page: number) => void
}

const Table: FC<ITable> = (props) => {

    const [directions, setDirections] = useState<boolean[]>([])
    const [filtered, setFiltered] = useState(props.data)
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    
    const totalPages = props.totalCount / rowsPerPage
    const first = (page - 1) * rowsPerPage
    const chunk = first + rowsPerPage
    const display = filtered
    
    const handleFilter = () => {
        const filter = props.data.filter((item) => {
            const result = props.filter?.indexes.filter((field) => {
                return String(item[field].value).includes(props.filter?.query ?? '')
            })
            return result?.length
        })
        setFiltered(filter)
    }

    const handleSort = (index: number) => {
        const dataClone = filtered.slice(0)
        const directionsClone = Array(directions.length).fill(true)
        dataClone.sort((a, b) => {
            if (a[index].value < b[index].value) return directions[index] ? -1 : 1
            if (a[index].value > b[index].value) return  directions[index] ? 1 : -1
            return 0
        })  
        directionsClone[index] = !directions[index]
        setFiltered(dataClone)
        setDirections(directionsClone)
    }

    const handleRowsPerPage = (rows: number) => {
        setRowsPerPage(rows)
        setPage(1)
    }

    useEffect(() => {
        setFiltered(props.data)
        setDirections(Array(props.header.length).fill(true))
    }, [props.data])    

    useEffect(() => {
        handleFilter()
    }, [props.filter])


    useEffect(() => {
        props.onPaginationClick?.(page,rowsPerPage)
    },[page])

    useEffect(() => {
        props.onRowsPerPageClick?.(rowsPerPage,page)
    },[rowsPerPage])

    return (
        <>
            <table className={styles.table}>
                <thead>
                    <tr>
                        {
                            props.header.map((item, key) => (
                                <th key={key}>
                                    <span onClick={() => handleSort(key)}>
                                        <Text type={'medium'} color={'medium'} >{item}</Text>
                                        {
                                            (
                                                directions[key]
                                                &&
                                                <ArrowDownIcon variant={'bold'} color={'medium'}/>
                                            )
                                            ||
                                                <ArrowUpIcon variant={'bold'} color={'medium'} />
                                        }
                                    </span>
                                </th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        display.map((item: any, key: number) => (
                            <tr
                                className={props.onRowClick && styles.clickable}
                                key={key}
                                onClick={() => props.onRowClick?.(item,key)}
                            >
                                {
                                    item.map((field: any, key2: number) => {
                                        if (field.hidden) return null
                                        return (
                                            <td key={key2}>
                                                {field.display}
                                                {!field.display && <Text>{field.value}</Text>}
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>

            {
                // Pagination controls
                props.totalCount > 0
                &&
                <div className={styles.footer}>
                    <span className={styles.controls}>
                        <Text color={'medium'} >Rows per page: {rowsPerPage}&nbsp;</Text>
                        <IconMenu
                            icon={<ArrowDownIcon color={'medium'}/>}
                            position={'left'}
                        >
                            <MenuItem>
                                <Link onClick={() => handleRowsPerPage(10)}>
                                    <Text color={'inherit'}>10 rows per page</Text>
                                </Link>
                            </MenuItem>
                            <MenuItem>
                                <Link onClick={() => handleRowsPerPage(25)}>
                                    <Text color={'inherit'}>25 rows per page</Text>
                                </Link>
                            </MenuItem>
                            <MenuItem>
                                <Link onClick={() => handleRowsPerPage(50)}>
                                    <Text color={'inherit'}>50 rows per page</Text>
                                </Link>
                            </MenuItem>
                        </IconMenu>
                        
                    </span>
                    <span className={styles.controls}>
                        <Text color={'medium'} >
                            {first + 1} - {chunk < props.totalCount ? chunk : props.totalCount} of {props.totalCount}
                        </Text>
                        <Link onClick={() => setPage(prev => prev > 1 ? prev - 1 : prev)}>
                            <ArrowLeftIcon color={'medium'} />
                        </Link>
                        <Link onClick={() => setPage(prev => prev < totalPages ? prev + 1 : prev)}>
                            <ArrowRightIcon color={'medium'} />
                        </Link>
                    </span>
                </div>
            }
        </>
    )
}

export default Table
