import { FC, Fragment, useEffect, useRef, useState } from 'react'
import { useAxios } from '../../hooks/axioshook'
import Button from '../../shared_components/button/Button'
import Input from '../../shared_components/input/Input'
import Loading from '../../shared_components/loading/Loading'
import Select from '../../shared_components/select/Select'
import Text from '../../shared_components/text/Text'
import { Design, DesignNew, Item, Order, OrderResponse, Part } from '../../types/types'
import styles from './IssuePOPage.module.scss'
import LineItemForm from './LineItemForm'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AxiosError } from 'axios'
import Alert from '../../shared_components/alert/Alert'
import React from 'react'

const IssuePOPage: FC = () => {

    const params = useParams()
    const navigate = useNavigate()
    const persona = process.env.REACT_APP_PERSONA?.toUpperCase()
    const location = useLocation();    
    const [ pageLabel ] = useState({ action: '', orderType: '' })
    const [showError, setShowError] = useState(false)
    const [errorType, setErrorType] = useState('')

    if(location.pathname.includes("amend")) pageLabel.action = "Amend"
    if(location.pathname.includes("view")) pageLabel.action = "View"
    if(location.pathname.includes("issue")) pageLabel.action = "Issue a"
    if(persona === 'BUYER') pageLabel.orderType = 'Purchase Order'
    if(persona !== 'BUYER') pageLabel.orderType = 'Sales Order'
    if(location.pathname.includes("acknowledge")) {
        pageLabel.action = "Acknowledge Purchase Order with Exceptions"
        pageLabel.orderType = ''
    }

    const [acknowledge, setAcknowledge] = useState(false)
    const defaultLineItem = (index: number,designNew? : DesignNew,part? : Part) => {
        return {
            index,
            recipeId: '' || designNew?.id as string,
            description: '' || designNew?.name as string,
            price: 0 || designNew?.price as number,
            quantity: 0 || part?.quantity as number,
            priceType: '' || part?.priceType as string,
            deliveryTerms: '' || part?.deliveryTerms as string,
            exportClassification: '' || part?.exportClassification as string,
            requiredBy: '' || part?.requiredBy as string,
            confirmedReceiptDate: '' ||  part?.requiredBy as string,
            currency: '' || part?.currency as string,
            unitOfMeasure: '' || part?.unitOfMeasure as string,
            lineText: '' || part?.lineText as string,
            deliveryAddress: {
                line1: '' || part?.deliveryAddress.line1 as string,
                line2: '' || part?.deliveryAddress.line2 as string,
                city: '' || part?.deliveryAddress.city as string,
                postcode: '' || part?.deliveryAddress.postcode as string,
            },
        }
    }

    const handleLineItemChange = (lineItemForm: Item) => {
        const newItems = updatedForm.current.items.map((item) => {
            if(item.index === lineItemForm.index) {
                return lineItemForm
            }
            return item
        })  
        setForm({...updatedForm.current, items: newItems})
    }   
    
    const removeLineItem = (formIndex: number) => {
        setLineItemComponents(lineItemComponents => {
            return lineItemComponents.filter((value) => value.props.index !== formIndex).map((item,newIndex) => {
                return React.cloneElement(item,{index : newIndex})
            })
        })
        setForm({...updatedForm.current, items: updatedForm.current.items.filter((item,index) => {
            return index !== formIndex
        })})
    }


    let design: Design = (location.state && location.state.design ) ? location.state.design : null;
    let designNew:DesignNew = {
            id: design?.id || '',
            externalId: design?.externalId || '',
            name: design?.name || '',
            supplier: design?.supplier || '',
            price: design?.price || 0
        }
    const order: OrderResponse = ((location.state && location.state.order ) ? location.state.order : null)
    const [lineItemComponents, setLineItemComponents] = useState([
        <LineItemForm
            index={0}
            onChange={handleLineItemChange}
            designNew={designNew}
            status=""
            onClose={removeLineItem}
        />
    ])

    
    const defaultOrder = (designNew: DesignNew) => {
        return {
            externalId: '',
            businessPartnerCode: '',
            supplier: '',
            status: '',
            items: [defaultLineItem(0,designNew)],
        }
    }

    const [form, setForm] = useState<Order>(defaultOrder(designNew))

    const addLineItem = () => {
            setLineItemComponents([
                ...lineItemComponents,
                <LineItemForm
                    index={form.items.length}
                    status=""
                    onChange={handleLineItemChange}
                    onClose={removeLineItem}
                />
            ])
            setForm({...form, items: [...form.items, defaultLineItem(form.items.length)]})
    }

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target
        setForm({...form, [name]: value})
    }
    

    // This fixes the closure (stale state) issue with handleLineItemChange
    const updatedForm = useRef<Order>(defaultOrder(designNew))
    useEffect(() => {
        updatedForm.current = form
    }, [form])

    useEffect(() => {
        const populateData = async () => {
                let lineItemComponents:any = []
                let items = await Promise.all(order.parts.map(async (item:Part,index:number) => {
                let part = JSON.parse(JSON.stringify(item))
                let requiredBy = new Date(part.requiredBy)
                let confirmedReceiptDate = new Date(part.confirmedReceiptDate)
                part.requiredBy = requiredBy.getFullYear() + '-' +  ('0' + (requiredBy.getMonth()+ 1)).slice(-2) + '-' + ('0' + requiredBy.getDate()).slice(-2)
                part.confirmedReceiptDate = confirmedReceiptDate.getFullYear() + '-' +  ('0' + (confirmedReceiptDate.getMonth()+ 1)).slice(-2) + '-' + ('0' + confirmedReceiptDate.getDate()).slice(-2)
                let address = part.deliveryAddress.split(',')
                part.deliveryAddress = {
                    line1: '',
                    line2: '',
                    city: '',
                    postcode: ''
                }
                part.deliveryAddress.line1 = address[0]
                part.deliveryAddress.line2 = address[1]
                part.deliveryAddress.city = address[2]
                part.deliveryAddress.postcode = address[3]
                lineItemComponents.push(<LineItemForm
                    index={index}
                    onChange={handleLineItemChange}
                    status={order.status}
                    designNew={part.recipe}
                    part={part}
                    acknowledge={acknowledge}
                    onClose={removeLineItem}
                />)
                    return defaultLineItem(index,part.recipe,part)
                }))
                setLineItemComponents(lineItemComponents)
                setForm({
                    externalId: '' || order.externalId,
                    businessPartnerCode: '' || order.businessPartnerCode,
                    supplier: '' || order.supplier,
                    status: '' || order.status,
                    items:items,
                })
        }
        if(order){
            populateData()
        }
    },[acknowledge])
    // Add loop when adding lineItems are enabled
    const validForm = Boolean(
        form.externalId && form.businessPartnerCode && form.items.every((value) => {
            return  value.quantity && value.unitOfMeasure &&
            value.currency && value.lineText && value.requiredBy &&
            value.exportClassification && value.deliveryTerms &&
            value.deliveryAddress.line1 && value.deliveryAddress.city &&
            value.deliveryAddress.postcode && value.recipeId && value.description &&
            value.price && value.priceType
        })
    )
    // Backend request
    const issuePORequest = useAxios()
    const handleOrderAcceptance = async () => {
        await issuePORequest.fetchData({
            method: 'POST',
            url: `/order/${order.id}/acceptance`
        })
        navigate(`/orders/${params.id}`)
    }

    const handleOrderCancel = async () => {
        await issuePORequest.fetchData({
            method: 'POST',
            url: `/order/${order.id}/cancellation`
        })
        navigate(`/orders/${params.id}`)
    }

    const handleOrderAmendment = async () => {
        await issuePORequest.fetchData({
            method: 'POST',
            url: `/order/${order.id}/amendment`,
            data: {
                items: [{
                    id: order.parts[0].partId,
                    requiredBy:  new Date(form.items[0].requiredBy).toISOString(),
                    recipeId: form.items[0].recipeId,
                    price: Number(form.items[0].price),
                    quantity: Number(form.items[0].quantity),
                    currency: form.items[0].currency,
                    deliveryTerms: form.items[0].deliveryTerms,
                    deliveryAddress: `${form.items[0].deliveryAddress.line1}, ${form.items[0].deliveryAddress.line2 || ''}, ${form.items[0].deliveryAddress.city}, ${form.items[0].deliveryAddress.postcode}`,
                    lineText: form.items[0].lineText,
                    exportClassification: form.items[0].exportClassification,
                    unitOfMeasure: form.items[0].unitOfMeasure,
                    priceType: form.items[0].priceType,
                    description: form.items[0].description,
                    confirmedReceiptDate: new Date(form.items[0].confirmedReceiptDate).toISOString(),
                }],
            },
        })
        navigate(`/orders/${params.id}`)
    }

    const handleOrderAcknowledgement = async () => {
        await issuePORequest.fetchData({
            method: 'POST',
            url: `/order/${order.id}/acknowledgement`,
            data: {
                items: [{
                    id: order.parts[0].partId,
                    requiredBy:  new Date(form.items[0].requiredBy).toISOString(),
                    recipeId: form.items[0].recipeId,
                    price: Number(form.items[0].price),
                    quantity: Number(form.items[0].quantity),
                    currency: form.items[0].currency,
                    deliveryTerms: form.items[0].deliveryTerms,
                    deliveryAddress: `${form.items[0].deliveryAddress.line1}, ${form.items[0].deliveryAddress.line2 || ''}, ${form.items[0].deliveryAddress.city}, ${form.items[0].deliveryAddress.postcode}`,
                    lineText: form.items[0].lineText,
                    exportClassification: form.items[0].exportClassification,
                    unitOfMeasure: form.items[0].unitOfMeasure,
                    priceType: form.items[0].priceType,
                    description: form.items[0].description,
                    confirmedReceiptDate: new Date(form.items[0].confirmedReceiptDate).toISOString(),
                }],
            },
        })
        navigate(`/orders/${params.id}`)
    }

    const handleCancel = () => {
        navigate(-1)
    }

    const suggestExceptions = () => {
        setAcknowledge(true)
    }
    // error handle
    useEffect(() => {
        if(issuePORequest.error?.response?.status === 500){
            if((issuePORequest.error?.response?.data as AxiosError).message === 'duplicate externalId found'){
                setErrorType('Design ID already exists on the system')
                setShowError(true)
            }
            else {
                setErrorType((issuePORequest.error?.response?.data as AxiosError).message)
                setShowError(true)
            }
        }
    }, [issuePORequest.error])

    useEffect(() => {
        if(!issuePORequest.loading && issuePORequest.response?.status === 201) {
            navigate(`/orders`)
        }
    }, [issuePORequest.loading])


    const groupItems = async () => {
        let items = form.items.map((value) => {
            return {
                requiredBy:  new Date(value.requiredBy).toISOString(),
                recipeId: value.recipeId,
                price: Number(value.price),
                quantity: Number(value.quantity),
                currency: value.currency,
                deliveryTerms: value.deliveryTerms,
                deliveryAddress: `${value.deliveryAddress.line1}, ${value.deliveryAddress.line2 || ''}, ${value.deliveryAddress.city}, ${value.deliveryAddress.postcode}`,
                lineText: value.lineText,
                exportClassification: value.exportClassification,
                unitOfMeasure: value.unitOfMeasure,
                priceType: value.priceType,
                description: value.description,
                confirmedReceiptDate: new Date(value.confirmedReceiptDate).toISOString(),
            }
        })

        return items
    }

    const handleSubmit = async () => {

        await issuePORequest.fetchData({
            method: 'POST',
            url: '/order',
            data: {
                externalId: form.externalId,
                supplier: form.businessPartnerCode,
                businessPartnerCode: form.businessPartnerCode,
                items: await groupItems(),
            },
        })
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.title}>
                    <Text size={'x-lg'} type={'bold'}>{pageLabel.action} {pageLabel.orderType}</Text>
                    <Button type={'minimal'} className={styles.cancelbutton}
                        onClick={() => navigate(-1)}
                    >
                        Cancel
                    </Button>
                </div>
                <div className={styles.columns}>
                    <div className={styles.inputs}>
                        <Input name={'externalId'} label={'External ID'} onChange={handleOnChange} required defaultValue={order?.externalId || ''}
                        disabled={order && order.status ? true : false}/>
                    </div>
                    <div className={styles.inputs}>
                        <Select
                            label={'Business Partner Code'}
                            placeholder={order?.businessPartnerCode || '- Select a business partner code -'}
                            items={[{label: 'Maher - EXT010390', value: 'MAHER'}, {label: 'Third Party - EXT010745', value: 'some-code'}]}
                            onChange={value => setForm({...form, businessPartnerCode: value})}
                            required disabled={order && order.status ? true :  false}
                        />
                    </div>
                 </div>
                <hr/>
            </div>

            <div className={styles.content}>
                <>
                    {
                        lineItemComponents.map((item, index) => (
                            <Fragment key={index}>
                                {item}
                            </Fragment>
                        ))
                    }
                    {persona !== 'SUPPLIER' && <div className={styles.addItem}>
                        {/* Enable button when functionality is to be released */}
                        <Button disabled={lineItemComponents.length >= 10} type={'secondary'} onClick={addLineItem}>Add line item</Button>
                    </div>}
                </>
            </div>
            <div className={acknowledge ? styles.button3 : styles.button}>
                {
                    ((persona === 'SUPPLIER' && !acknowledge)
                    &&
                    (order?.status === 'Submitted' || order?.status === 'Amended')
                    &&
                    <>
                        <Button type={'secondary'}
                            disabled={!validForm || order?.status === 'Amended'}
                            onClick={suggestExceptions}
                        >
                            Suggest Exceptions
                        </Button>
                        <Button
                            disabled={!validForm}
                            onClick={handleOrderAcceptance}
                        >
                            Accept Order
                        </Button>
                    </>)
                    ||
                    ((persona === 'SUPPLIER' && acknowledge)
                    &&
                    (order?.status === 'Submitted' || order?.status === 'Amended')
                    &&
                    <>
                        <Button type={'secondary'}
                            disabled={!validForm}
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!validForm}
                            onClick={handleOrderAcknowledgement}
                        >
                            Send
                        </Button>
                    </>)
                     ||
                     ((persona !== 'SUPPLIER')
                     &&
                     (order?.status === 'AcknowledgedWithExceptions')
                     &&
                     <>
                         <Button type={'secondary'}
                             disabled={!validForm}
                             onClick={handleOrderCancel}
                         >
                             Cancel Order
                         </Button>
                         <Button
                             disabled={!validForm}
                             onClick={handleOrderAmendment}
                         >
                             Accept Amendments
                         </Button>
                     </>)
                }
            </div>
            <div className={styles.button2}>
                {
                    (persona === 'BUYER')
                    &&
                    !order
                    &&
                    <Button
                    disabled={!validForm}
                    onClick={handleSubmit}
                >
                    Share With Supplier
                </Button> 
                }
            </div>
            <Alert
                message={'Error'}
                details={errorType}
                onClose={()=> setShowError(false)}
                open={showError}
                type={'red'}
            />
            {
                issuePORequest.loading
                &&
                <Loading />
            }
        </div>
    )
}

export default IssuePOPage
