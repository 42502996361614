import { FC, useEffect, useState } from "react"
import { useAxios } from "../../hooks/axioshook"
import { CloseIcon } from "../../shared_components/icons/Icons"
import Input from "../../shared_components/input/Input"
import Link from "../../shared_components/link/Link"
import Select from "../../shared_components/select/Select"
import Text from "../../shared_components/text/Text"
import { Design, Item, Part, DesignNew } from "../../types/types"
import styles from './IssuePOPage.module.scss'

interface ILineItemForm {
    index: number,
    status: string,
    onChange: (value: Item) => void,
    designNew?: DesignNew
    part? : Part
    acknowledge? : boolean
    onClose: (value: number) => void
}

const LineItemForm: FC<ILineItemForm> = (props) => {

    const recipes = useAxios()
    const [designs, setDesigns] = useState<DesignNew[]>([])
    const [design, setDesign] = useState<DesignNew>(props.designNew as DesignNew)
    const [part, setPart] = useState<Part>(props.part as Part)
    const [confirmedReceiptDate, setConfirmedReceiptDate] = useState('')
    useEffect(() => {
        if(props.part){
            setPart(props.part as Part)
            setForm({...form,currency: props.part.currency,deliveryAddress: props.part.deliveryAddress, deliveryTerms: props.part.deliveryTerms,exportClassification: props.part.exportClassification,lineText: props.part.lineText,priceType:props.part.priceType,quantity:props.part.quantity,unitOfMeasure:props.part.unitOfMeasure,requiredBy: props.part.requiredBy, confirmedReceiptDate: props.part.confirmedReceiptDate})
            props.onChange({...form,currency: props.part.currency,deliveryAddress: props.part.deliveryAddress, deliveryTerms: props.part.deliveryTerms,exportClassification: props.part.exportClassification,lineText: props.part.lineText,priceType:props.part.priceType,quantity:props.part.quantity,unitOfMeasure:props.part.unitOfMeasure,requiredBy: props.part.requiredBy, confirmedReceiptDate: props.part.confirmedReceiptDate})
        }
    },[props.part])

    useEffect(() => {
        if(props.designNew){
            setDesign(props.designNew as Design)
            setForm({...form,description: props.designNew.name,price: props.designNew.price, recipeId: props.designNew.id})
            props.onChange({...form, description: props.designNew.name,price: props.designNew.price, recipeId: props.designNew.id})
        }
    },[props.designNew])
    
    useEffect(() => {
        recipes.fetchData({
            method: 'GET',
            url: '/recipe'
        })
    }, [recipes.hasToken])

    useEffect(() => {
        if(recipes.response?.status === 200) {
            setDesigns(recipes.response?.data)
        }
    }, [recipes.loading])
    
    const [form, setForm] = useState<Item>({
        index: props.index,
        recipeId: '' || design?.id,
        description: '' || design?.name,
        price: 0 || design?.price,
        quantity: 0 || part?.quantity,
        priceType: '' || part?.priceType,
        deliveryTerms: '' || part?.deliveryTerms,
        exportClassification: '' || part?.exportClassification,
        requiredBy: '' || part?.requiredBy,
        currency: '' || part?.currency,
        unitOfMeasure: '' || part?.unitOfMeasure,
        lineText: '' || part?.lineText,
        deliveryAddress: {
            line1: '' || part?.deliveryAddress.line1,
            line2: '' || part?.deliveryAddress.line2,
            city: '' || part?.deliveryAddress.city,
            postcode: '' || part?.deliveryAddress.postcode,
        },
        confirmedReceiptDate: '' || part?.confirmedReceiptDate
    })
    
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target
        if(name === 'requiredBy')
        {
            setConfirmedReceiptDate(value)
            setForm({...form, [name]: value,confirmedReceiptDate: value})
            props.onChange({...form, [name]: value, confirmedReceiptDate: value})
        }
        else{
            setForm({...form, [name]: value})
            props.onChange({...form, [name]: value})
        }
    }

    const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target
        setForm({...form, deliveryAddress: {...form.deliveryAddress, [name]: value}})
        props.onChange({...form, deliveryAddress: {...form.deliveryAddress, [name]: value}})
    }

    const handleSelectChange = (name: string, value: string) => {
        setForm({...form, [name]: value})
        props.onChange({...form, [name]: value})
    }

    const handleDesignChange = (value: string) => {
        setDesign(designs.filter(item => {
           return item.id === value
        })[0])
        handleSelectChange('recipeId', value)
    }
    useEffect(() => {
        if(design){
            setForm({...form,description: design.name,price: design.price, recipeId: design.id})
            props.onChange({...form, description: design.name,price: design.price, recipeId: design.id})
        }
    },[design])

    useEffect(() => {
        if(part){
            setForm({...form,currency: part.currency,deliveryAddress: part.deliveryAddress, deliveryTerms: part.deliveryTerms,exportClassification: part.exportClassification,lineText: part.lineText,priceType:part.priceType,quantity:part.quantity,unitOfMeasure:part.unitOfMeasure,requiredBy: part.requiredBy, confirmedReceiptDate: part.confirmedReceiptDate, price: part.price})
            props.onChange({...form,currency: part.currency,deliveryAddress: part.deliveryAddress, deliveryTerms: part.deliveryTerms,exportClassification: part.exportClassification,lineText: part.lineText,priceType:part.priceType,quantity:part.quantity,unitOfMeasure:part.unitOfMeasure,requiredBy: part.requiredBy, confirmedReceiptDate: part.confirmedReceiptDate, price: part.price})
        }
    },[part])

    return (
        <>
            <Text size={'lg'} type={'bold'}>Line Item {props.index + 1}
            {props.index > 0
                &&
            <Link
                className={styles.close}
                onClick={() => props.onClose(props.index)}
            >
                <CloseIcon color={'dark'} />
            </Link>
            }
            </Text>
            <div className={styles.columns}>
                <div className={styles.inputs}>
                    <Select
                        label={'Design ID'}
                        placeholder={ design?.externalId || '- Select a Design ID -'}
                        items={designs.map(item => {return {label: item.externalId, value: item.id}})}
                        onChange={value => handleDesignChange(value)}
                        required disabled={props.status ? true :  false}
                    />
                    <Input name={'description'} label={'Description'} max={32} onChange={handleOnChange} required defaultValue={design?.name || ''} disabled={(props.status ? true : false) || (design?.name ? true :  false)}/>
                    <Input type={'number'} min={0} name={'price'} label={'Price'} onChange={handleOnChange} className={props?.acknowledge ? styles.acknowledge : ""} disabled={((props.status ? true : false) ||  (design?.price ? true :  false)) && !props?.acknowledge} defaultValue={part?.price || design?.price || ''}/>
                    <Input type={'number'} min={0} name={'quantity'} label={'Quantity'} onChange={handleOnChange} className={props?.acknowledge ? styles.acknowledge : ""} required defaultValue={part?.quantity || ''} 
                        disabled={(props.status ? true : false) && !props?.acknowledge}/>
                    <Select
                        label={'Price Type'}
                        placeholder={part?.priceType || '- Select a price type -'}
                        items={[{label: 'FIXED', value: 'FIXED'},{label: 'FIRM', value: 'FIRM'},{label: 'Maximum', value: 'Maximum'}]}
                        onChange={value => handleSelectChange('priceType', value)}
                        required disabled={props.status ? true : false }
                    />
                    <Input name={'deliveryTerms'} label={'Delivery Terms'} max={32} onChange={handleOnChange} required defaultValue={part?.deliveryTerms || ''}
                        disabled={props.status ? true : false }/>
                    <Input name={'exportClassification'} label={'Export Classification'} max={32} onChange={handleOnChange} required defaultValue={part?.exportClassification || ''}
                        disabled={props.status ? true : false }/>
                </div>
                <div className={styles.inputs}>
                    <Input name={'requiredBy'} type={'date'} label={'Required Date'} onChange={handleOnChange} required defaultValue={part?.requiredBy || ''} 
                        disabled={props.status ? true : false }/>
                    <Input name={'confirmedReceiptDate'} type={'date'} label={'Confirmed Receipt Date'} className={props?.acknowledge ? styles.acknowledge : ""} onChange={handleOnChange} required defaultValue={part?.confirmedReceiptDate || confirmedReceiptDate || ''} 
                        disabled={!props?.acknowledge}/>
                    <Select
                        label={'Currency'}
                        placeholder={part?.currency || '- Select a currency -'}
                        items={[{label: 'GBP', value: 'GBP'}]}
                        onChange={value => handleSelectChange('currency', value)}
                        required disabled={props.status ? true : false }
                    />
                    <Select
                        label={'Units of Measure'}
                        placeholder={part?.unitOfMeasure || '- Select a unit of measure -'}
                        items={[{label: 'Each', value: 'Each'}]}
                        onChange={value => handleSelectChange('unitOfMeasure', value)}
                        required disabled={props.status ? true : false }
                    />
                    <Input name={'lineText'} label={'Line Text'} max={64} onChange={handleOnChange} required defaultValue={part?.lineText || ''} className={props?.acknowledge ? styles.acknowledge : ""} disabled={(props.status ? true : false) && !props?.acknowledge}/>
                    <div className={styles.address}>
                        <Input name={'line1'} label={'Delivery to Address'} placeholder={'Address line 1'} onChange={handleAddressChange} required defaultValue={part?.deliveryAddress.line1 || ''}
                            disabled={props.status ? true : false }/>
                        <Input name={'line2'} placeholder={'Address line 2 (optional)'} onChange={handleAddressChange} defaultValue={part?.deliveryAddress.line2 || ''}
                            disabled={props.status ? true : false }/>
                        <Input name={'city'} placeholder={'City'} onChange={handleAddressChange} defaultValue={part?.deliveryAddress.city || ''}
                            disabled={props.status ? true : false }/>
                        <Input name={'postcode'} placeholder={'Postcode'} onChange={handleAddressChange} defaultValue={part?.deliveryAddress.postcode || ''}
                            disabled={props.status ? true : false }/>
                    </div>
                </div>
            </div>
            <hr/>
        </>
    )
}

export default LineItemForm
