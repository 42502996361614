import { Link } from 'react-router-dom'
import styles from './NavBar.module.scss'
import Breadcrumbs from '../breadcrumbs/Breadcrumbs'
import AccountMenu from '../accountMenu/AccountMenu'
import bae_logo from '../../assets/logos/baesystems.svg'
import maher_logo from '../../assets/logos/maher.svg'
import { HomeIcon, MenuIcon } from '../../shared_components/icons/Icons'

const persona = process.env.REACT_APP_PERSONA?.toUpperCase()
let logo : string

if(persona === 'BUYER'){
    logo = bae_logo
} else if (persona === 'SUPPLIER'){
    logo = maher_logo
} else{
    logo = ''
}

const NavBar = () => (
    
    <nav className={styles.container}>
        <div className={styles.left}>
            <Link to="/"><HomeIcon color={'medium'}/></Link>
            <Breadcrumbs/>
        </div>
        <div className={styles.right}>
            <AccountMenu username={'John Smith'}/>
            <img src={logo} />
        </div>
    </nav>
)

export default NavBar
