import { FC } from "react"
import Modal from "../modal/Modal"
import styles from './Loading.module.scss'

const Loading: FC = () => (
    <Modal>
        <div className={styles.loader} />
    </Modal>
)

export default Loading
