import { FC } from 'react'
import styles from './NotificationsHeader.module.scss'
import background from '../../assets/images/notifications_bg.jpg'
import Text from '../../shared_components/text/Text'
import Input from '../../shared_components/input/Input'
import Menu from '../../shared_components/menu/Menu'
import Button from '../../shared_components/button/Button'
import MenuItem from '../../shared_components/menu/MenuItem'
import Filter from '../../shared_components/filter/Filter'

const supplierOptions = [
    {label: 'Maher', value: 'maher'},
    {label: 'Supplier A', value: 'a'},
    {label: 'Supplier B', value: 'b'},
    {label: 'Supplier C', value: 'c'},
]

const typeOptions = [
    {label: '???', value: 'none'},
]

const NotificationsHeader: FC = () => (
    <div className={styles.container}>
        <div className={styles.image}>
            <img src={background}/>
        </div>
        <div className={styles.filter}>
            <Text size={'x-lg'} color={'inherit'}>Notifications</Text>
            <div>
                <div>
                    <Input className={styles.search} type={'search'} placeholder={'Search for PO or Part Number '} />
                    <Menu className={styles.menu} label={'Filter Search'}>
                        <MenuItem>
                            <Filter name={'supplier'} label={'Supplier'} options={supplierOptions}/>
                        </MenuItem>
                        <MenuItem>
                            <Filter name={'type'} label={'Notification Type'} options={typeOptions}/>
                        </MenuItem>
                    </Menu>
                </div>
                <Button>Mark all as read</Button>
            </div>
        </div>
    </div>
)

export default NotificationsHeader
