import { FC, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import LineDetail from "../../components/lineDetail/LineDetail"
import { useAxios } from "../../hooks/axioshook"
import Button from "../../shared_components/button/Button"
import { ArrowRightAltIcon, MessageIcon } from "../../shared_components/icons/Icons"
import Input from "../../shared_components/input/Input"
import Loading from "../../shared_components/loading/Loading"
import Text from "../../shared_components/text/Text"
import { OrderResponse } from "../../types/types"
import styles from './DetailsPOPage.module.scss'

const DetailsPOPage: FC = () => {

    const params = useParams()
    const order = useAxios()

    const navigate = useNavigate()

    const [content, setContent] = useState<OrderResponse>()

    useEffect(() => {
        order.fetchData({
            method: 'GET',
            url: `/order?externalId=${params.id}`,
        })
    }, [order.hasToken])

    useEffect(() => {
        if(order.response?.status === 200) {
            setContent(order.response.data)
        }
    }, [order.loading])

    const handleButtonClick = (type: string) => {
        type === 'review' ? navigate(`/orders/${params.id}/amend`, {
            state: {
                order : order?.response?.data
            }
        }) : navigate(`/orders/${params.id}/view-order`,{
            state: {
                order : order?.response?.data
            }
        })
    }

    const persona = process.env.REACT_APP_PERSONA?.toUpperCase()
    const statusContext = (persona === 'SUPPLIER' ? 
        content?.status === 'Submitted' || content?.status === 'Amended'
        :
        content?.status === 'AcknowledgedWithExceptions'
    )
    const title = (persona === 'SUPPLIER' ? 'Sales' : 'Purchase')
    const searchKey = (persona === 'SUPPLIER' ? 'Job' : 'Part')

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <Text size={'x-lg'} type={'bold'}>
                    {title} Order: {content?.externalId}
                </Text>
                <div className={styles.buttons}>
                <Input type={'search'} placeholder={'Search for '+ searchKey +' Number'} />
                <Button type={"notification"}>
                    <MessageIcon variant={'linear'}/>
                    <div className={styles.badge}>
                    <Text color={'inherit'} size={'sm'}>1</Text>
                    </div>
                </Button>
                </div>
            </div>
            <br/>
            {
                statusContext
                &&
                <Button type={'secondary'} onClick={index => handleButtonClick('review')}>Review Purchase Order</Button>
            }
            {
                !statusContext
                &&
                <Button type={'minimal'} onClick={index => handleButtonClick('view')}>
                    View full PO<ArrowRightAltIcon color={'dark'}/>
                </Button>
            }
            <br/><br/><br/>
            {
                (persona === 'SUPPLIER')
                &&
                <>
                <Text size={'lg'}>Buyer:  </Text><Text size={'lg'} type={'bold'}>{content?.buyer}</Text>
                </>
            }
            {
                (persona !== 'SUPPLIER')
                &&
                <>
                <Text size={'lg'}>Business Partner Code:  </Text><Text size={'lg'} type={'bold'}>{content?.businessPartnerCode}</Text>
                </>
            }            
            <br/><br/>
            {
                content &&
                <LineDetail order={content} />
            }
            {
                order.loading
                &&
                <Loading />
            }
        </div>
    )
}

export default DetailsPOPage
