import { FC, useEffect, useState } from 'react'
import { useAxios } from '../../hooks/axioshook'
import Button from '../../shared_components/button/Button'
import { CloseIcon } from '../../shared_components/icons/Icons'
import Link from '../../shared_components/link/Link'
import Modal from '../../shared_components/modal/Modal'
import Text from '../../shared_components/text/Text'
import styles from './UpdateProcess.module.scss'
import Loading from '../../shared_components/loading/Loading'
import Checkbox from '../../shared_components/checkbox/Checkbox'

interface IUpdateProcess {
    open?: boolean,
    buildId?: string,
    updateType?: string,
    forecastedDeliveryDate?: string
    onClose: () => void
}

const UpdateProcess: FC<IUpdateProcess> = (props) => {
    const [value, setValue] = useState(false)

    const updateJob = useAxios()
    const handleSubmit = () => {
        // Send progress update for build
        updateJob.fetchData({
            method: 'POST',
            url: `/build/${props.buildId}/progress-update`,
            data: {
                updateType: props.updateType,
                completionEstimate: props.forecastedDeliveryDate
            },
        })
    }
    useEffect(() => {
        if (updateJob.response?.status === 201) {
            window.location.reload();
        }
    }, [updateJob.loading])

    const getCheckboxAction = () => {
        if (props.updateType === "Encapsulation Completed") return "Capsule Filling Completed"
        if (props.updateType === "Machining and NDT Completed") return "Machining & NDT Completed"
    }

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.checked)
    }

    return (
        <>
            {
                props.open
                &&
                <Modal>
                    <div className={styles.container}>
                        <Link
                            className={styles.close}
                            onClick={props.onClose}
                        >
                            <CloseIcon color={'dark'} />
                        </Link>
                        <Text size={'x-lg'} type={'bold'}>Update Process</Text>
                        <div className={styles.type}>
                            <Text>Select the checkbox to confirm that the following process has been completed.</Text>
                            <Checkbox onChange={handleCheckboxChange}>{
                                getCheckboxAction()
                            }
                            </Checkbox>
                        </div>
                        <div>
                            <Button
                                onClick={props.onClose}
                                className={styles.button}
                                type='secondary'
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleSubmit}
                                className={styles.button}
                                disabled={value === false}
                            >
                                Update
                            </Button>
                        </div>
                        {
                            (updateJob.loading)
                            &&
                            <Loading />
                        }
                    </div>
                </Modal>
            }
        </>
    )
}

export default UpdateProcess
