import { FC, useState } from 'react'
import styles from './IconMenu.module.scss'

export interface IIconMenu extends React.HTMLAttributes<HTMLDivElement> {
    icon: React.ReactNode,
    position?: 'right' | 'left' | 'middle',
}

const IconMenu: FC<IIconMenu> = (props) => {
    
    const [open, setOpen] = useState(false)
    
    const handleBlur = (e: { currentTarget: HTMLDivElement }) => {    
        const target = e.currentTarget  
        requestAnimationFrame(() => {
          if (!target.contains(document.activeElement)) {
            setOpen(false)
          }
        })
    }
    
    return (
        <div className={styles.container}
            tabIndex={0}
            onBlur={handleBlur}
        >
            <div
                className={`${props.className} ${styles.icon}`}
                onClick={() => setOpen(!open)}
            >
                {props.icon}
            </div>
            {
                open
                &&
                <div
                    className={`${styles.items} ${styles[props.position ?? 'right']}`}
                    onClick={() => setOpen(false)}
                >
                    {props.children}
                </div>
            }
        </div>
    )
}

export default IconMenu
