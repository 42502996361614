import { FC } from 'react'
import Text from '../text/Text'
import styles from './Checkbox.module.scss'

export interface ICheckbox extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string,
}

const Checkbox: FC<ICheckbox> = (props) => (
    <div className={styles.container}>
        <input 
            className={props.className} 
            type={'checkbox'}
            name={props.name}
            value={props.value}
            checked={props.checked}
            onChange={props.onChange}
            disabled={props.disabled}
        />
            <span className={styles.label}>
                {
                    props.label &&
                    <Text>{props.label}</Text>
                }
                <Text>{props.children}</Text>
            </span>
    </div>
)

export default Checkbox
