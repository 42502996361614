import { FC } from "react"
import styles from './OverviewHeader.module.scss'
import background from '../../assets/images/overview_bg.jpg'
import Text from "../../shared_components/text/Text"
import { QuestionIcon } from "../../shared_components/icons/Icons"
import { useNavigate } from "react-router-dom"
import Tooltip from "../../shared_components/tooltip/Tooltip"

interface IOverviewHeader {
    partsOrdered: number,
    stats: number[],
}

const labels = ['Design', 'Order', 'Manufacture & Monitor', 'Ship & Receive']
var urls = ['', '', '', '']
let percentage: number

const OverviewHeader: FC<IOverviewHeader> = (props) => {

    const navigate = useNavigate()

    const persona = process.env.REACT_APP_PERSONA?.toUpperCase()
    let overviewHeader : string

    if (persona === 'BUYER') {
        overviewHeader = 'PARTS ORDERED'
        // Add planned URL for BUYER only, everyone else dont see planned URL
        urls = ['/orders/planned', '', '', '']
    } else if (persona === 'SUPPLIER') {
        overviewHeader = 'JOBS RAISED'
    } else {
        overviewHeader = 'PARTS ORDERED'
    }

    return (
        <div className={styles.container}>
            <div className={styles.image}>
                <img src={background} alt={'background'} />
            </div>
            <div className={styles.header}>
                <div className={styles.title}>
                    <Text size={'lg'} type={'bold'} >{props.partsOrdered} {overviewHeader}</Text>
                    <div>
                        <Tooltip
                            content={
                                <>
                                    <Text color={'inherit'} type={'semibold'} >Design:</Text>
                                    <Text color={'inherit'} >&nbsp;Design & Specification uploaded.</Text>
                                    <br/>
                                    <Text color={'inherit'} type={'semibold'} >Order:</Text>
                                    <Text color={'inherit'} >&nbsp;Purchase Order shared.</Text>
                                    <br/>
                                    <Text color={'inherit'} type={'semibold'} >Manufacture & Monitor:</Text>
                                    <Text color={'inherit'} >&nbsp;Manufacturing job started.</Text>
                                    <br/>
                                    <Text color={'inherit'} type={'semibold'} >Ship & Receive:</Text>
                                    <Text color={'inherit'} >&nbsp;Finished part shipped and invoice received.</Text>
                                </>
                            }
                            arrow={'right'}
                        >
                            <QuestionIcon />
                        </Tooltip>
                    </div>
                </div>
                <div className={styles.content}>
                    {
                        props.stats.map((item, key) => {
                            percentage = item / props.partsOrdered * 100
                            return (
                                <div
                                    key={key} className={styles.item}
                                    style={{ width: `${percentage}%` }}
                                    onClick={() => navigate(urls[key])}
                                >
                                    <div className={`${styles.bar} ${styles[`color${key}`]}`} />
                                    <Text>{labels[key]}</Text>
                                    <div className={styles.label}>
                                        <div className={`${styles.box} ${styles[`color${key}`]}`} />
                                        <Text>{item}</Text>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default OverviewHeader
