import { FC, useState } from 'react'
import { EyeIcon, EyeSlashIcon } from '../icons/Icons'
import Text from '../text/Text'
import styles from './Input.module.scss'

export interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
    name?: string,
    label?: string,
    required?: boolean,
    type?: 'text' |'number' | 'password' | 'search' | 'date',
    tip?: string | React.ReactNode,
    onChange?: React.ChangeEventHandler<HTMLInputElement>,
    min?: number,
    acknowledge?: boolean
    max?: number,
}

const Input: FC<IInput> = (props) => {

    const [hidden, setHidden] = useState(true)

    const handleShowPassword = () => {
        setHidden(prev => !prev)
    }

    //defaults
    let type = props.type ?? 'text'
    const autoComplete = type === 'text' ? 'on' : 'off'
    if(type === 'password') {
        type = hidden ? 'password' : 'text'
    }

    return (
        <div className={styles.container}>
            <div>
                <Text type={'bold'}>{props.label}</Text>
                {
                    props.required
                    && 
                    <Text size={'lg'} color={'error'}>&nbsp;*</Text>
                }
            </div>
            
            <div className={styles.middle}>
                <input 
                    className={props.className}
                    name={props.name}
                    type={props.type}
                    autoComplete={autoComplete}
                    placeholder={props.placeholder}
                    required={props.required}
                    onChange={props.onChange}
                    min={props.min}
                    maxLength={props.max}
                    disabled={props.disabled}
                    defaultValue={props.defaultValue}
                />

                {
                    props.type === 'password'
                    &&
                    (
                        hidden ?
                            <EyeIcon 
                                className={styles.icon}
                                onClick={handleShowPassword}
                            />
                        :
                            <EyeSlashIcon 
                                className={styles.icon}
                                onClick={handleShowPassword}
                            />
                    )
                }
            </div>

            <Text size={'sm'}>{props.tip}</Text>
        </div>
    )
}

export default Input
