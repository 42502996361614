import { QRCodeSVG } from 'qrcode.react'
import { FC } from 'react'
import Button from '../button/Button'
import { BinIcon, CloseIcon, EditIcon } from '../icons/Icons'
import Input from '../input/Input'
import Link from '../link/Link'
import Modal from '../modal/Modal'
import Text from '../text/Text'
import styles from './Popup.module.scss'

export interface IPopup {
    type: 'cancel' | 'cancelNoReason' | 'delete' | 'save' | 'qrcode',
    open?: boolean,
    data?: string,
    onCancel: () => void,
    onAction?: () => void,
    onReason?: () => void,
}

const Popup: FC<IPopup> = (props) => (
    <>
    {
        props.open
        &&
        <Modal>
            <div className={styles.container}>
                {
                props.type === 'cancel'
                &&
                <>
                    <div className={styles.icon}>
                        <CloseIcon/>
                    </div>
                    <div className={styles.content}>
                        <Text type={'semibold'}>Cancel Order?</Text>
                        <br/>
                        <Text size={'sm'}>You must provide a reason for cancelling this order</Text>
                        <Text color={'error'}> *</Text>
                        <Input
                            className={styles.reason}
                            onChange={props.onReason}
                        />
                        <br/>
                        <div className={styles.buttons}>
                            <Button type={'secondary'} onClick={props.onCancel}>Keep Order</Button>
                            <Button type={'primary'} onClick={props.onAction}>Cancel Order</Button>
                        </div>
                    </div>
                </>
                }

                {
                props.type === 'cancelNoReason'
                &&
                <>
                    <div className={styles.icon}>
                        <CloseIcon/>
                    </div>
                    <div className={styles.content}>
                        <Text type={'semibold'}>Cancel Order?</Text>
                        <br/>
                        <Text size={'sm'}>Please confirm if you want to cancel this order.</Text>
                        <br/><br/>
                        <div className={styles.buttons}>
                            <Button type={'secondary'} onClick={props.onCancel}>Keep Order</Button>
                            <Button type={'primary'} onClick={props.onAction}>Cancel Order</Button>
                        </div>
                    </div>
                </>
                }

                {
                props.type === 'delete'
                &&
                <>
                    <div className={styles.icon}>
                        <BinIcon/>
                    </div>
                    <div className={styles.content}>
                        <Text type={'semibold'}>Delete Design?</Text>
                        <br/>
                        <Text size={'sm'}>Please confirm if you want to delete this new design.</Text>
                        <br/><br/>
                        <div className={styles.buttons}>
                            <Button type={'secondary'} onClick={props.onCancel}>Keep Design</Button>
                            <Button type={'primary'} onClick={props.onAction}>Delete Design</Button>
                        </div>
                    </div>
                </>
                }

                {
                props.type === 'save'
                &&
                <>
                    <div className={styles.icon}>
                        <EditIcon/>
                    </div>
                    <div className={styles.content}>
                        <Text type={'semibold'}>Save Draft?</Text>
                        <br/>
                        <Text size={'sm'}>
                            Please confirm if you want to save this design as a planned purchase<br/>order and exit.
                        </Text>
                        <br/><br/>
                        <div className={styles.buttons}>
                            <Button type={'secondary'} onClick={props.onCancel}>Continue Editing</Button>
                            <Button type={'primary'} onClick={props.onAction}>Save & Exit</Button>
                        </div>
                    </div>
                </>
                }
                {
                props.type === 'qrcode'
                &&
                <>
                    <div className={styles.qrcode}>
                        <Link 
                            className={styles.close}
                            onClick={props.onCancel}
                        >
                        <CloseIcon color={'dark'}/>
                        </Link>
                        <Text>QR code for Part ID.</Text>
                        <QRCodeSVG value={props.data || ''} />                        
                    </div>
                </>
                }
            </div>
        </Modal>
    }
    </>
)

export default Popup
