import { FC, useEffect, useState } from 'react'
import { useAxios } from '../../hooks/axioshook'
import Bullet from '../../shared_components/bullet/Bullet'
import { ArrowDownIcon, ArrowUpIcon, ClockIcon } from '../../shared_components/icons/Icons'
import Link from '../../shared_components/link/Link'
import Step from '../../shared_components/stepper/Step'
import Stepper from '../../shared_components/stepper/Stepper'
import Text from '../../shared_components/text/Text'
import styles from './PartTracking.module.scss'
import { HistoryResponse, PartResponse } from "../../types/types"
import { getClockColor, getStepper } from '../../helper/helper'

interface IPartTracking {
    part: PartResponse,
}

const PartTracking: FC<IPartTracking> = (props) => {

    const history = useAxios()
    const [open, setOpen] = useState(false)
    //const [content, setContent] = useState<HistoryResponse>()

    const persona = process.env.REACT_APP_PERSONA?.toUpperCase()
    let ordersText: string
    let lIHeaderText: string
    let lIText: string
    let lIStepOne: string

    useEffect(() => {
        if (open && history.response?.status !== 200) {
            history.fetchData({
                method: 'GET',
                url: `/order/${props.part.id}/history`
            })
        }
    }, [open, history.hasToken])


    if (persona === 'BUYER') {
        ordersText = 'Part Number'
        lIHeaderText = 'Lines'
        lIText = 'Part'
        lIStepOne = 'Purchase Order Shared'
    } else if (persona === 'SUPPLIER') {
        ordersText = 'Job #'
        lIHeaderText = 'Jobs'
        lIText = 'Job'
        lIStepOne = 'Purchase Order Received'
    } else {
        ordersText = 'Job #'
        lIHeaderText = 'Jobs'
        lIText = 'Job'
        lIStepOne = 'Purchase Order Received'
    }

    return (
        <div className={styles.container}>
            <div className={styles.order}>
                <div className={styles.header}>
                    <Text type={'medium'}>{ordersText}: {props.part.id}</Text>
                    <span>
                        {
                            (
                                open &&
                                <Link onClick={() => setOpen(false)}><ArrowUpIcon color={'dark'} /></Link>
                            ) || (
                                !open &&
                                <Link onClick={() => setOpen(true)}><ArrowDownIcon color={'dark'} /></Link>
                            )
                        }
                    </span>
                </div>
            </div>
           
                        <div  className={`${styles.content} ${open && styles.open}`}>
                            <div className={styles.label}>
                            This is a test
                            
                            <Text type={'medium'}>{lIText} Number: {props.part.confirmedReceiptDate}</Text>
                            </div>

                        </div>
                    
            
        </div>
    )
}

export default PartTracking
