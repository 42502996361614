import { FC } from "react";
import styles from './Step.module.scss'
import { SettingsIcon, TickCircleIcon } from "../icons/Icons";
import Text from "../text/Text";

export interface IStep extends React.HTMLAttributes<HTMLDivElement> {
    type?: 'done' | 'current' | 'next' | 'delayed'
    label: string,
    date: string,
    lastStep?: boolean,
}

const Step: FC<IStep> = (props) => {

    //defaults
    const type = props.type ?? 'next'
    const lastStep = props.lastStep ?? false

    return (
        <div className={`${props.className} ${styles.container}`}>
            <div className={styles.graph}>
                {
                    (
                        type === 'done'
                        &&
                        <div className={styles.done}>
                            <TickCircleIcon variant={'bold'} color={'green'}/>
                        </div>
                    )
                    ||
                    (
                        type === 'current'
                        &&
                        <div className={styles.current}>
                            <SettingsIcon variant={'bold'} />
                        </div>
                    )
                    ||
                    (
                        type === 'delayed'
                        &&
                        <div className={styles.delayed}>
                            <SettingsIcon variant={'bold'} color={'yellow'} />
                        </div>
                    )
                    ||
                    (
                        type === 'next'
                        &&
                        <div className={styles.next} />
                    )
                }
                {
                    !lastStep
                    &&
                    <hr/>
                }
            </div>
            <div className={`${styles.label} ${lastStep && styles.last}`}>
                <Text size={'sm'}>{props.label}</Text>
                <br/>
                <Text size={'sm'} color={'medium'} >{props.date}</Text>
            </div>
        </div>
    )
}

export default Step
